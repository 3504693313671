import React, { CSSProperties, useEffect, useRef, useState } from 'react';

import classNames from 'helpers/classNames';
import useFeatureFlags from 'helpers/hooks/useFeatureFlags';
import { __ } from 'helpers/i18n';

import { Button, ClickAwayListener } from 'components';
import TrixEditor from 'components/TrixEditor';
import { Editor } from 'components/TrixEditor/TrixEditor';

type Props = {
  value: string | null | undefined;
  disabled?: boolean;
  display?: boolean;
  onChange?: (html: string, text: string) => void;
  onEditorReady?: (editor: Editor) => void;
  autoFocus?: boolean;
  placeholder?: string;
  allowImageUpload?: boolean;
  mergeTags?: Array<{
    trigger: string;
    tags: Array<{ name: string; tag: string }>;
  }>;
  onBlur?: () => void;
  onEscapeKeyPress?: () => any;
  onSaveButtonClick?: () => any;
  style?: CSSProperties;
  className?: string;
  toolbarAlwaysVisible?: boolean;
  fixedTagSelector?: boolean;
};

const RichTextEditor = ({
  value,
  disabled,
  display,
  autoFocus,
  placeholder,
  allowImageUpload,
  mergeTags,
  onEditorReady,
  onChange,
  style,
  className,
  onSaveButtonClick,
  toolbarAlwaysVisible,
  fixedTagSelector,
  onBlur,
  onEscapeKeyPress,
}: Props) => {
  const [isManagingFocus, setIsManagingFocus] = useState(false);
  const editorRef = useRef<Editor | null>(null);

  const handleClickOutside = (_event: Event) => {
    if (isManagingFocus) {
      setIsManagingFocus(false);
      onBlur && onBlur();
    }
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    onEscapeKeyPress && e.keyCode === 27 && onEscapeKeyPress();
  };

  const handleFocus = () => {
    if (!isManagingFocus) {
      setIsManagingFocus(true);
    }
  };

  useEffect(() => {
    const editor = editorRef.current;
    if (editor && !disabled) {
      if (autoFocus) editor.element.focus();
      editor.element.addEventListener('keydown', handleKeyDown);
    }
    return () => {
      if (editor) {
        editor.element.removeEventListener('keydown', handleKeyDown);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoFocus, disabled]);

  const featureFlags = useFeatureFlags();

  return (
    <ClickAwayListener onClickAway={handleClickOutside}>
      <div
        className={classNames('trix-content content', className, {
          'trix-disabled': disabled,
          'trix-focused': isManagingFocus,
          'trix-hide-toolbar': !toolbarAlwaysVisible && !isManagingFocus,
          'trix-display': display,
        })}
        onFocus={handleFocus}
        style={style}
      >
        <TrixEditor
          value={value === null ? undefined : value}
          onChange={(html, text) => onChange && onChange(html, text)}
          placeholder={placeholder}
          allowImageUpload={featureFlags.richTextImages && allowImageUpload}
          onEditorReady={editor => {
            editorRef.current = editor;
            if (disabled) {
              editor.element.contentEditable = 'false';
            }
            if (onEditorReady) {
              onEditorReady(editor);
            }
          }}
          mergeTags={mergeTags}
          fixedTagSelector={fixedTagSelector}
        />

        {isManagingFocus && onSaveButtonClick && (
          <Button
            color="primary"
            size="small"
            onClick={() => onSaveButtonClick()}
            className="save-button no-link-icon"
          >
            {__('Save')}
          </Button>
        )}
      </div>
    </ClickAwayListener>
  );
};

export default RichTextEditor;
