import { sortBy } from 'lodash/collection';
import React, { Fragment, useEffect, useState } from 'react';
import { components } from 'react-select';

import {
  AutoAddRule,
  ReviewCycle,
  UserFilterType,
  UserFilterableFieldType,
} from 'models';

import can from 'helpers/can';
import { useAppDispatch, useUserFilterableFields } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { post } from 'redux/actions/api';

import { Button, Divider, Icon, Select } from 'components';

import RuleCard from './RuleCard';

type Props = {
  reviewCycle: ReviewCycle;
};

const RULE_COUNT_LIMIT = 10;

const UserCriterionOption = props => (
  <components.Option
    // eslint-disable-next-line react/prop-types
    className={`user-criterion-option-${props.value}`}
    {...props}
  />
);

export default function DynamicConfiguration({ reviewCycle }: Props) {
  const availableFilters = useUserFilterableFields();

  const options = availableFilters.map((value: UserFilterableFieldType) => ({
    label: value.label,
    value: value.slug,
  }));
  const sortedOptions = sortBy(options, field => field.label.toLowerCase());

  const userFilterType = reviewCycle.autoAddRules[0]?.userCriterion;
  const selectedProfileCriterionOption = sortedOptions.filter(
    option => option.value === userFilterType
  )[0];

  // The pickers refresh their options when this state changes
  const [triggerRefreshCount, setTriggerRefresh] = useState<number>(0);
  const stringifiedRules = JSON.stringify(reviewCycle.autoAddRules);
  useEffect(() => {
    setTriggerRefresh(prevTrigger => prevTrigger + 1);
  }, [stringifiedRules]);

  const dispatch = useAppDispatch();
  const createNewAutoAddRule = () =>
    dispatch(
      post(`review_cycles/${reviewCycle.id}/auto_add_rules`, {
        userCriterion: userFilterType,
      })
    );
  const resetAutoAddRules = (newUserFilterType: UserFilterType) => {
    if (userFilterType === newUserFilterType) return;
    return dispatch(
      post(`review_cycles/${reviewCycle.id}/auto_add_rules/reset`, {
        userCriterion: newUserFilterType,
      })
    );
  };

  const findFilterTypeLabel = (rule: AutoAddRule): string => {
    const filter = availableFilters.find(
      element => element.slug === rule.userCriterion
    );
    return !!filter ? filter.label.toLowerCase() : __('Missing label');
  };

  return (
    <Fragment>
      <Select
        testClassName="test-auto-add-criterion-select"
        options={sortedOptions}
        value={selectedProfileCriterionOption}
        placeholder={__('Choose a profile criteria')}
        // @ts-ignore TSFIXME: Fix strictNullChecks error
        onChange={option => resetAutoAddRules(option.value)}
        components={{ Option: UserCriterionOption }}
      />
      {reviewCycle.autoAddRules.length > 0 && (
        <div className="flex flex-col gap-4 mt-4">
          {reviewCycle.autoAddRules.map((rule, index) => (
            <Fragment key={rule.id}>
              {index !== 0 && <Divider className="my-0" />}
              <RuleCard
                index={index}
                autoAddRule={rule}
                filterTypeLabel={findFilterTypeLabel(rule)}
                reviewCycleId={reviewCycle.id}
                isDestroyEnabled={reviewCycle.autoAddRules.length > 1}
                triggerRefreshCount={triggerRefreshCount}
              />
            </Fragment>
          ))}
          <Divider className="my-0" />
          <Button
            color="secondary"
            style={{ width: 'max-content' }}
            onClick={createNewAutoAddRule}
            className="add-auto-add-rule"
            disabled={
              !can({
                perform: 'create_auto_add_rule',
                on: reviewCycle,
              })
            }
            disabledExplanation={
              reviewCycle.autoAddRules.length >= RULE_COUNT_LIMIT
                ? __('You have reached the maximum number of rules')
                : __('The configured rules already target all your employees')
            }
          >
            <Icon className="align-middle mr-1" name="add" />
            {__('Add a rule')}
          </Button>
        </div>
      )}
    </Fragment>
  );
}
