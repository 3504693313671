import React, { CSSProperties } from 'react';

type Props = {
  items: string[];
  style: CSSProperties;
};

export default function BulletList({ items, style }: Props) {
  return (
    <ul style={{ ...style, listStyle: 'disc' }}>
      {items.map(item => (
        // eslint-disable-next-line react/jsx-key
        <li>{item}</li>
      ))}
    </ul>
  );
}
