import React, { CSSProperties } from 'react';
import { Link } from 'react-router-dom';

import classNames from 'helpers/classNames';

import {
  Button,
  Hoverable,
  Icon,
  Level,
  LevelLeft,
  LevelRight,
  Text,
} from 'components';

type Props = {
  title: string;
  subtitle: string;
  target: string;
  style?: CSSProperties;
};

const DirectReportQuickLink = ({ title, subtitle, target, style }: Props) => {
  return (
    <Hoverable
      render={isHovered => (
        <Link
          className={classNames(
            'direct-report-quick-link',
            isHovered && 'is-hovered'
          )}
          to={target}
          style={style}
        >
          <Level>
            <LevelLeft
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
            >
              <Text
                color={isHovered ? 'darkest-grey' : 'pelo-light-grey'}
                transformation="uppercase"
                weight="semibold"
                size={7}
              >
                {title}
              </Text>
              <Text
                color={isHovered ? 'darkest-grey' : 'pelo-light-grey'}
                size={6}
                style={{ marginTop: -3 }}
              >
                {subtitle}
              </Text>
            </LevelLeft>
            <LevelRight>
              <Button
                size="small"
                color="primary"
                className={isHovered ? 'is-hovered' : undefined}
                style={{ opacity: 1 }}
              >
                <Icon size="small" name="arrow_forward" />
              </Button>
            </LevelRight>
          </Level>
        </Link>
      )}
    />
  );
};

export default DirectReportQuickLink;
