import React from 'react';

import classNames from 'helpers/classNames';

import Text, { type Props as TextProps } from './Text';

export type TitleSize = 1 | 2 | 3 | 4 | 5 | 6 | 7;

type HeadingProps = TextProps & {
  textClassName?: string;
};

type TitleProps = Omit<HeadingProps, 'size'> & {
  size: TitleSize;
};

/**
 * @deprecated Use the <Text> component with one of its presets instead,
 * see in titles.tsx to find the equivalent preset
 */
export function Title({ size, ...otherProps }: TitleProps) {
  switch (size) {
    case 1:
      return <Title1 {...otherProps} />;
    case 2:
      return <Title2 {...otherProps} />;
    case 3:
      return <Title3 {...otherProps} />;
    case 4:
      return <Title4 {...otherProps} />;
    case 5:
      return <Title5 {...otherProps} />;
    case 6:
      return <Title6 {...otherProps} />;
    case 7:
      return <Title7 {...otherProps} />;
    default:
      return null;
  }
}

/**
 * @deprecated Use the <Text> component with one of its presets instead,
 * see in titles.tsx to find the equivalent preset
 */
export function SubTitle({ size, ...otherProps }: TitleProps) {
  switch (size) {
    case 6:
      return <SubTitle6 {...otherProps} />;
    case 7:
      return <SubTitle7 {...otherProps} />;
    default:
      return null;
  }
}

/**
 * @deprecated Use the <Text> component with 32bs1
 */
export function Title1({
  className,
  textClassName,
  overflowEllipsis,
  style,
  ...otherProps
}: HeadingProps) {
  return (
    <h1
      className={classNames('title is-1', className, {
        'is-text-overflow-ellipsis': !!overflowEllipsis,
      })}
      style={style}
    >
      <Text {...otherProps} className={textClassName} />
    </h1>
  );
}

/**
 * @deprecated Use the <Text> component, closest is 24bs2 (but bold)
 */
export function Title2({
  className,
  textClassName,
  overflowEllipsis,
  style,
  ...otherProps
}: HeadingProps) {
  return (
    <h2
      className={classNames('title is-2', className, {
        'is-text-overflow-ellipsis': !!overflowEllipsis,
      })}
      style={style}
    >
      <Text {...otherProps} className={textClassName} />
    </h2>
  );
}

/**
 * @deprecated Use the <Text> component with 24bs2
 */
export function Title3({
  className,
  textClassName,
  overflowEllipsis,
  style,
  ...otherProps
}: HeadingProps) {
  return (
    <h3
      className={classNames('title is-3', className, {
        'is-text-overflow-ellipsis': !!overflowEllipsis,
      })}
      style={style}
    >
      <Text {...otherProps} className={textClassName} />
    </h3>
  );
}

/**
 * @deprecated Use the <Text> component, closest are 18bs5 or 24bs2 (smaller or bigger)
 */
export function Title4({
  className,
  textClassName,
  overflowEllipsis,
  style,
  ...otherProps
}: HeadingProps) {
  return (
    <h4
      className={classNames('title is-4', className, {
        'is-text-overflow-ellipsis': !!overflowEllipsis,
      })}
      style={style}
    >
      <Text {...otherProps} className={textClassName} />
    </h4>
  );
}

/**
 * @deprecated Use the <Text> component with 18bs5
 */
export function Title5({
  className,
  textClassName,
  overflowEllipsis,
  style,
  ...otherProps
}: HeadingProps) {
  return (
    <h5
      className={classNames('title is-5', className, {
        'is-text-overflow-ellipsis': !!overflowEllipsis,
      })}
      style={style}
    >
      <Text {...otherProps} className={textClassName} />
    </h5>
  );
}

/**
 * @deprecated Use the <Text> component with 14bs6
 */
export function Title6({
  className,
  textClassName,
  overflowEllipsis,
  style,
  ...otherProps
}: HeadingProps) {
  return (
    <h6
      className={classNames('title is-6', className, {
        'is-text-overflow-ellipsis': !!overflowEllipsis,
      })}
      style={style}
    >
      <Text {...otherProps} className={textClassName} />
    </h6>
  );
}

/**
 * @deprecated Use the <Text> component with color="light" and preset 14s6
 */
export const SubTitle6 = ({
  className,
  textClassName,
  overflowEllipsis,
  style,
  ...otherProps
}: HeadingProps) => (
  <h6
    className={classNames('subtitle is-6', className, {
      'is-text-overflow-ellipsis': !!overflowEllipsis,
    })}
    style={style}
  >
    <Text {...otherProps} className={textClassName} />
  </h6>
);

/**
 * @deprecated Use the <Text> component with 13bs7
 */
export function Title7({
  className,
  textClassName,
  overflowEllipsis,
  style,
  ...otherProps
}: HeadingProps) {
  return (
    <h6
      className={classNames('title is-7', className, {
        'is-text-overflow-ellipsis': !!overflowEllipsis,
      })}
      style={style}
    >
      <Text {...otherProps} className={textClassName} />
    </h6>
  );
}

/**
 * @deprecated Use the <Text> component with color="light" and preset 13s7
 */
export const SubTitle7 = ({
  className,
  textClassName,
  overflowEllipsis,
  style,
  ...otherProps
}: HeadingProps) => (
  <h6
    className={classNames('subtitle is-7', className, {
      'is-text-overflow-ellipsis': !!overflowEllipsis,
    })}
    style={style}
  >
    <Text {...otherProps} className={textClassName} />
  </h6>
);
