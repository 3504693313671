import React, { createContext, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { compose } from 'redux';

import { ReviewCycle } from 'models/ReviewCycle';

import { useActiveUser, useCurrentOrganization } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import { anyTemplateWithSkillsInCycle } from 'helpers/models/reviewCycle';
import {
  pathToManagerReviewCycleAnalytics,
  pathToManagerReviewCycleResults,
  pathToManagerReviewCycleSkills,
  pathToMyTeamReviews,
} from 'helpers/paths';
import queryString from 'helpers/queryString';

import { DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { getUserFiltersFromQueryParams } from 'lib/dataLoader/pagination/paginationParams';
import { get } from 'redux/actions/api';

import { FetchContainer, PageHeader, PageTitle } from 'components';
import { ActiveFilters } from 'components/Filters/types';
import { withMatchParams } from 'components/HOCs/withMatchParams';

import ExportMenuButton from 'scenes/admin/reviewCycles/Manage/Header/ExportMenuButton';
import LaunchingCycle from 'scenes/admin/reviewCycles/Manage/LaunchingCycle';

import ManagerCycleAnalytics from './ManagerCycleAnalytics';
import ManagerCycleResults from './ManagerCycleResults';
import ManagerCycleSkills from './ManagerCycleSkills';

type DataContextType = {
  withUserMultiFilters: boolean;
  userFilters: ActiveFilters;
  setUserFilters: (filters: ActiveFilters) => void;
};

export const DataContext = createContext<DataContextType>(
  {} as DataContextType
);

type AfterMatchParamsProps = {
  match: { id: string };
};

type AfterDataLoadedProps = AfterMatchParamsProps &
  DataLoaderProvidedProps & {
    reviewCycle: ReviewCycle;
  };

const ReviewCycleIndex = ({
  match,
  reviewCycle,
  isFetching,
  hasError,
}: AfterDataLoadedProps) => {
  const activeUser = useActiveUser();
  const { featureFlags } = useCurrentOrganization();
  const shouldShowSkillsTab =
    featureFlags.includes('mockDataForSkillsAndCareersAnalytics') ||
    featureFlags.includes('skillsAndCareersAnalytics');

  const withUserMultiFilters = featureFlags.includes('userMultifilters');
  const defaultUserFilters = getUserFiltersFromQueryParams(
    // @ts-expect-error: TSFIXME
    queryString.parse(window.location.search)
  ) || {
    direct_reports: {
      label: activeUser.fullName,
      value: activeUser.id,
    },
  };

  const [userFilters, setUserFilters] =
    useState<ActiveFilters>(defaultUserFilters);

  return (
    <DataContext.Provider
      value={{ withUserMultiFilters, userFilters, setUserFilters }}
    >
      <FetchContainer
        isFetching={isFetching}
        hasError={hasError}
        render={() => {
          if (reviewCycle.status === 'launching') {
            return (
              <>
                <PageTitle title={[reviewCycle.name, __('Manager')]} />
                <LaunchingCycle reviewCycle={reviewCycle} />
              </>
            );
          }

          const tabItems = [
            {
              label: __('Progress'),
              to: pathToManagerReviewCycleAnalytics(match.id),
              testName: 'test-progress-link',
            },
            {
              label: __('Results'),
              to: pathToManagerReviewCycleResults(match.id),
              testName: 'test-results-link',
            },
          ];

          if (
            shouldShowSkillsTab &&
            anyTemplateWithSkillsInCycle(reviewCycle)
          ) {
            tabItems.push({
              label: __('Skills'),
              to: pathToManagerReviewCycleSkills(match.id),
              testName: 'test-skills-link',
            });
          }

          return (
            <>
              <PageHeader
                title={reviewCycle.name}
                withBackButton
                backButtonProps={{
                  target: pathToMyTeamReviews(reviewCycle.interactionType),
                  children: __('Back to home'),
                }}
                tabItems={tabItems}
                actions={[
                  // eslint-disable-next-line react/jsx-key
                  <ExportMenuButton
                    reviewCycle={reviewCycle}
                    formats={['xlsx']}
                  />,
                ]}
              />

              <Routes>
                <Route
                  path="analytics"
                  element={<ManagerCycleAnalytics reviewCycle={reviewCycle} />}
                />
                <Route
                  path="results"
                  element={<ManagerCycleResults reviewCycle={reviewCycle} />}
                />
                <Route
                  path="skills"
                  element={<ManagerCycleSkills reviewCycle={reviewCycle} />}
                />
              </Routes>
            </>
          );
        }}
      />
    </DataContext.Provider>
  );
};

export default compose<React.ComponentType>(
  withMatchParams,
  newDataLoader({
    fetch: ({ match }: AfterMatchParamsProps) =>
      get(`review_cycles/${match.id}`),
    hydrate: {
      reviewCycle: {
        abilities: {},
        defaultTemplate: {},
        templates: {},
        lastPendingExportAction: {},
      },
    },
  })
)(ReviewCycleIndex);
