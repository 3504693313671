import React from 'react';
import { useNavigate } from 'react-router-dom';

import type { ObjectivePeriod, TeamObjectivePeriod } from 'models';

import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import {
  pathToOrganizationObjectivePeriod,
  pathToTeamObjectivesForObjectivePeriod,
} from 'helpers/paths';

import { deleteTeamObjectivePeriod } from 'redux/actions/resources';

import {
  Can,
  Level,
  LevelLeft,
  LevelRight,
  Link,
  TabletViewPort,
  Testable,
  Text,
  Title,
} from 'components';

import AddTeamButton from './AddTeamButton';

type Props = {
  teamObjectivePeriods: Array<TeamObjectivePeriod>;
  objectivePeriod: ObjectivePeriod;
  teamSlug: string | undefined | null;
};

const TeamSideList = ({
  teamObjectivePeriods,
  objectivePeriod,
  teamSlug,
}: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const removeTeamFromPeriod = (teamObjectivePeriod: TeamObjectivePeriod) =>
    dispatch(deleteTeamObjectivePeriod(teamObjectivePeriod, navigate));

  return (
    <TabletViewPort>
      <div
        style={{ position: 'fixed', width: '20%', top: 146, minWidth: 140 }}
        className="side-team-list"
      >
        <Title
          size={5}
          weight="semibold"
          color="pelo-grey"
          style={{ marginBottom: 10 }}
        >
          {teamSlug ? (
            <Testable name="test-all-teams-link">
              <Link
                to={pathToOrganizationObjectivePeriod(objectivePeriod.slug)}
                style={{ textDecoration: 'none' }}
              >
                <span>{__('All teams')}</span>
                <Text
                  size={6}
                  style={{ position: 'relative', top: 3, marginLeft: 5 }}
                >
                  ↩︎
                </Text>
              </Link>
            </Testable>
          ) : (
            <span>{__('All teams')}</span>
          )}
        </Title>
        {teamObjectivePeriods.map(teamObjectivePeriod => {
          const { team } = teamObjectivePeriod;
          const isCurrentTeam = teamSlug === team.slug;
          return (
            <Level key={team.slug} className="team-list-item">
              <LevelLeft style={{ maxWidth: 'calc(100% - 25px)' }}>
                <Text
                  size={6}
                  color={isCurrentTeam ? 'primary' : 'dark-grey'}
                  key={team.id}
                  overflowEllipsis
                  overrideLinkColor
                >
                  {isCurrentTeam ? (
                    team.name
                  ) : (
                    <Link
                      to={pathToTeamObjectivesForObjectivePeriod(
                        teamObjectivePeriod.slug,
                        team.slug
                      )}
                    >
                      {team.name}
                    </Link>
                  )}
                </Text>
              </LevelLeft>
              <LevelRight>
                <Can perform="manage_team_objectives" on="organization">
                  <button
                    className="delete is-small"
                    onClick={() => removeTeamFromPeriod(teamObjectivePeriod)}
                  />
                </Can>
              </LevelRight>
            </Level>
          );
        })}
        {objectivePeriod && (
          <Can perform="manage_team_objectives" on="organization">
            <Testable name="test-add-team-button">
              <AddTeamButton
                style={{ marginTop: 10 }}
                objectivePeriod={objectivePeriod}
                teamObjectivePeriods={teamObjectivePeriods}
              />
            </Testable>
          </Can>
        )}
      </div>
    </TabletViewPort>
  );
};

export default TeamSideList;
