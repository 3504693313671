import React from 'react';

import { __ } from 'helpers/i18n';
import { pathToAuditReportDetails } from 'helpers/navigation';

import { BoxListItem, Column, Columns, Link, Tag } from 'components';

// eslint-disable-next-line react/prop-types
const AuditReportListItem = ({ auditReport }) => (
  <BoxListItem>
    <Columns
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
      isMobile
    >
      <Column size={5}>
        {/* eslint-disable-next-line react/prop-types */}
        <Link to={pathToAuditReportDetails(auditReport.id)}>
          {/* eslint-disable-next-line react/prop-types */}
          {auditReport.title}
        </Link>
      </Column>
      <Column size={4} className="flex gap-2 flex-wrap">
        {/* eslint-disable-next-line react/prop-types */}
        {auditReport.tags.map(tag => (
          <Tag key={`tag-${tag.id}`}>{tag.label}</Tag>
        ))}
      </Column>
      <Column size={2}>
        {/* eslint-disable-next-line react/prop-types */}
        {auditReport.delayInMonths} {__('months')}
      </Column>
    </Columns>
  </BoxListItem>
);

export default AuditReportListItem;
