import React from 'react';

import type { ParticipantEligibility } from 'models';

import {
  BoxListItem,
  Checkbox,
  Column,
  Columns,
  Icon,
  PullRight,
  Text,
} from 'components';

import UserAvatar from 'scenes/components/UserAvatar';

type DisabledReasonsLabelProps = {
  reasons: Array<string>;
};

function DisabledReasonsLabel({ reasons }: DisabledReasonsLabelProps) {
  const reason = reasons[0];

  return (
    <Text className="disabled-reasons-label" size={6} color="grey">
      <span
        title={reason}
        style={{
          display: 'inline-block',
          maxWidth: 150,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          verticalAlign: 'middle',
        }}
      >
        {reason}
      </span>{' '}
      <Icon name="block" />
    </Text>
  );
}

type Props = {
  eligibility: ParticipantEligibility;
  onRemove: (id: string) => Promise<void>;
  onAdd: (id: string) => Promise<void>;
};

export default function ParticipantListItem({
  eligibility,
  onRemove,
  onAdd,
}: Props) {
  const { reviewee, isEligible, isElected, reasons } = eligibility;

  return (
    <BoxListItem className="participant-list-item">
      <Columns
        className={`test-participant-item-${reviewee.id}`}
        contentVerticallyAligned
        isMobile
      >
        <Column size={7}>
          <UserAvatar user={reviewee} isDisabled={!isEligible} withJobTitle />
        </Column>

        <Column size={5}>
          <PullRight>
            {isEligible ? (
              <Checkbox
                style={{ marginLeft: '10px' }}
                isChecked={isElected}
                onChange={() =>
                  isElected ? onRemove(reviewee.id) : onAdd(reviewee.id)
                }
              />
            ) : (
              <DisabledReasonsLabel reasons={reasons} />
            )}
          </PullRight>
        </Column>
      </Columns>
    </BoxListItem>
  );
}
