import { sortBy } from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';

import type { DataLoaderProvidedProps } from 'lib/dataLoader';
import type { Entity } from 'models';
import type { AppDispatch } from 'redux/actions';

import { useAppDispatch, useOrganizationPlan } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import invariant from 'helpers/invariant';
import { pathToAdmin } from 'helpers/paths';
import confirmAsync from 'helpers/react/confirmAsync';

import { newDataLoader } from 'lib/dataLoader';
import { del, get, post, put } from 'redux/actions/api';
import { getDeleteEntitySideEffects } from 'redux/actions/resources/sideEffects';

import {
  Box,
  BoxList,
  Button,
  ContentContainer,
  DesignSystem,
  EmptyState,
  FetchContainer,
  PageHeader,
} from 'components';

import EntityItem from './EntityItem';
import emptyStateImg from './emptystate.png';

type AfterDataloaderProps = DataLoaderProvidedProps & {
  entities: Array<Entity>;
};

const EntityIndex = ({
  entities,
  isFetching,
  hasError,
  refetchData,
}: AfterDataloaderProps) => {
  const [creatingEntity, setCreatingEntity] = useState(false);
  const dispatch = useAppDispatch();
  const organizationPlan = useOrganizationPlan();

  const createEntity = async (id: string, nameParam: string) => {
    invariant(id === 'new', 'Cannot create an entity with a real id!');
    const name = nameParam.trim();

    if (!name) {
      setCreatingEntity(false);
      return;
    }

    try {
      await dispatch(post(`entities`, { entity: { name } }));
      setCreatingEntity(false);
      await refetchData();
    } catch (e: any) {
      setCreatingEntity(false);
      if (!e.isHandled) throw e;
    }
  };

  const updateEntityName = async (id: string, name?: string | null) => {
    invariant(id !== 'new', 'Cannot update name of new entity');

    if (!!name) {
      await dispatch(put(`entities/${id}`, { entity: { name } }));
    } else {
      await deleteEntity(id);
    }

    await refetchData();
  };

  const deleteEntityAction =
    (entity: Entity) => async (dispatch: AppDispatch) =>
      confirmAsync(
        __('Delete %1', entity.name),
        __(
          'Are you sure you want to delete administration entity %1?',
          <b>{entity.name}</b>
        ),
        {
          onConfirm: () => dispatch(del(`entities/${entity.id}`)),
          confirmLabel: __('Delete entity'),
          sideEffects: getDeleteEntitySideEffects(entity),
        }
      );

  const deleteEntity = async (id: string) => {
    const entity = entities.find((entity: Entity) => entity.id === id);
    invariant(entity, 'Id should be contained in entities list');

    await dispatch(deleteEntityAction(entity));
    await refetchData();
  };

  return (
    <DesignSystem version={2}>
      <FetchContainer
        isFetching={isFetching}
        hasError={hasError}
        render={() => {
          if (!organizationPlan.multiLevelAdminEnabled) {
            return <Navigate to={pathToAdmin()} />;
          }

          const displayPlaceHolder = entities.length === 0 && !creatingEntity;

          return (
            <>
              <PageHeader
                title={__('Entities')}
                actions={[
                  // eslint-disable-next-line react/jsx-key
                  <Button
                    color="primary"
                    disabled={creatingEntity}
                    onClick={() => setCreatingEntity(true)}
                  >
                    {__('Add an entity')}
                  </Button>,
                ]}
              />

              <ContentContainer>
                {!displayPlaceHolder ? (
                  <Box>
                    <BoxList>
                      {sortBy(entities, entity => moment(entity.createdAt)).map(
                        entity => (
                          <EntityItem
                            key={entity.id}
                            entity={entity}
                            persisted
                            onDelete={deleteEntity}
                            updateName={updateEntityName}
                          />
                        )
                      )}

                      {creatingEntity && (
                        <EntityItem
                          key="new-entity"
                          entity={{
                            id: 'new',
                            type: 'entity',
                            name: '',
                            usersCount: 0,
                            createdAt: '',
                          }}
                          persisted={false}
                          updateName={createEntity}
                        />
                      )}
                    </BoxList>
                  </Box>
                ) : (
                  <EmptyState
                    title={__('No entity created yet')}
                    src={emptyStateImg}
                    action={
                      <Button
                        color="primary"
                        onClick={() => setCreatingEntity(true)}
                        size="medium"
                      >
                        {__('Add an entity')}
                      </Button>
                    }
                  />
                )}
              </ContentContainer>
            </>
          );
        }}
      />
    </DesignSystem>
  );
};

export default newDataLoader({
  fetch: () => get('entities'),
  hydrate: { entities: {} },
})(EntityIndex) as React.ComponentType;
