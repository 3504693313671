import React, { useState } from 'react';

import type { OneOnOneUserReview, ReviewCycle, User, UserReview } from 'models';
import type { ReleaseActionInfo } from 'scenes/review/Review/ReleaseAction';

import breadcrumb from 'helpers/breadcrumb';
import can from 'helpers/can';
import useIsMobile from 'helpers/hooks/useIsMobile';
import { __, n__ } from 'helpers/i18n';
import { pathToUser } from 'helpers/paths';

import {
  Button,
  Icon,
  Link,
  PageHeader,
  ProductIcon,
  SavingStatusLabel,
  Text,
  Tooltip,
} from 'components';

import AttachedFilesModal from './AttachedFilesModal';
import MoreOptionsButton from './MoreOptionsButton';
import PrintActionButton from './PrintActionButton';

type Props = {
  reviewee?: User | null;
  reviewCycle?: ReviewCycle | null;
  review?: UserReview | null;
  backButtonTarget?: string;
  showSavingStatus?: boolean;
  shareAction: ReleaseActionInfo;
  refreshReview: () => Promise<void>;
};

export default function Topbar({
  reviewee,
  reviewCycle,
  review,
  backButtonTarget,
  showSavingStatus,
  refreshReview,
  shareAction,
}: Props) {
  const [isFilesModalActive, setIsFilesModalActive] = useState<boolean>(false);

  const shareButton = (
    <Button
      color="primary"
      disabled={!shareAction.isEnabled}
      onClick={!!shareAction.trigger ? shareAction.trigger : undefined}
    >
      <Icon style={{ margin: '-1px 4px 0 0' }} name="send" />
      {__('Share')}
    </Button>
  );

  const shareActionComponent = shareAction.isShown ? (
    shareAction.isEnabled ? (
      shareButton
    ) : (
      <Tooltip
        placement="bottom-end"
        content={
          shareAction.disabledReasons &&
          shareAction.disabledReasons.length > 0 && (
            <div style={{ maxWidth: '350px' }}>
              <Text size={6} weight="bold">
                {__('Sharing is not possible yet:')}
              </Text>
              {shareAction.disabledReasons &&
                shareAction.disabledReasons.map(reason => (
                  <li key={reason}>{reason}</li>
                ))}
            </div>
          )
        }
        style={{ marginTop: 16 }}
      >
        {shareButton}
      </Tooltip>
    )
  ) : null;

  const isMobile = useIsMobile();

  const canDownloadAttachedFiles: boolean =
    review?.interactionType === 'one_on_one' &&
    can({
      perform: 'download_attached_files',
      on: review,
    });

  return (
    <>
      <PageHeader
        // eslint-disable-next-line react/no-children-prop
        children={
          <div className="flex items-center">
            {!!review?.interactionType && (
              <ProductIcon productName={review.interactionType} />
            )}
            <div className="ml-4">
              <Text
                className="page-header__title-and-actions-container__title"
                preset="24bs2"
                testClassName="test-page-header__title-and-actions-container__title"
              >
                {reviewCycle?.name}
              </Text>
              {reviewee && (
                <div className="flex flex-col">
                  <Text preset="16bs5.5">
                    <Link isPrimary to={pathToUser(reviewee.id)}>
                      {reviewee.fullName}
                    </Link>
                  </Text>
                  <Text preset="13s7">{reviewee.jobTitle}</Text>
                </div>
              )}
            </div>
          </div>
        }
        actions={
          <>
            {isMobile && canDownloadAttachedFiles && (
              <Button
                color="secondary"
                isRounded
                hasIconOnly
                onClick={() => {
                  setIsFilesModalActive(true);
                }}
              >
                <Icon name="attach_file" />
              </Button>
            )}
            {review && <PrintActionButton review={review} />}

            {review && reviewCycle && (
              <MoreOptionsButton
                review={review}
                reviewCycle={reviewCycle}
                refreshReview={refreshReview}
              />
            )}

            {shareActionComponent}
          </>
        }
        statusInfo={
          !!showSavingStatus && (
            <SavingStatusLabel
              failedText={count =>
                n__(
                  'One comment could not be saved',
                  '%1 comments could not be saved',
                  count
                )
              }
            />
          )
        }
        withBackButton
        backButtonProps={{
          target: backButtonTarget || breadcrumb.getAnchor('user_review_list'),
          children: __('Back'),
        }}
      />
      {canDownloadAttachedFiles && isFilesModalActive && (
        <AttachedFilesModal
          isActive={isFilesModalActive}
          onClose={() => setIsFilesModalActive(false)}
          userReview={review as OneOnOneUserReview}
        />
      )}
    </>
  );
}
