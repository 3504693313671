import React from 'react';

import { useAppDispatch, useSession } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { htmlSuccessNotice } from 'redux/actions';

import { Icon } from 'components';
import { InformationModal } from 'components/modals';

type Props = {
  action: 'prepare' | 'launch';
  onClose: () => void;
};

const HowToLaunchManualSyncInformationModal = ({ action, onClose }: Props) => {
  const { organizationId } = useSession();
  const command = `heroku run:detached -a elevo-rails-production --size="performance-m" "rails sync:${action}_for_organization[${organizationId}]"`;
  const dispatch = useAppDispatch();

  return (
    <InformationModal
      isActive
      isLarge
      title={
        action === 'prepare' ? 'How to prepare a sync' : 'How to launch a sync'
      }
      onOK={onClose}
      // eslint-disable-next-line react/no-children-prop
      children={
        <div>
          {`To ${action} a sync for the current organization, run the following command in the terminal:`}
          <br />
          <br />
          <span>
            <code className="text-black">{command}</code>
            <Icon
              onClick={async () => {
                await navigator.clipboard.writeText(command);
                dispatch(htmlSuccessNotice(__('Text copied to clipboard')));
              }}
              name="content_copy"
              className="cursor-pointer ml-1"
            />
          </span>
          <br />
          <br />
          You will not need to wait for the sync to finish.
          <br />
          You can still monitor its status and download its report from the
          application.
        </div>
      }
      refreshContentOnOpening={false}
    />
  );
};

export default HowToLaunchManualSyncInformationModal;
