import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

export const withRouter = WrappedComponent => {
  // eslint-disable-next-line react/display-name
  return props => {
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    return (
      <WrappedComponent
        {...props}
        match={params}
        location={location}
        navigate={navigate}
      />
    );
  };
};
