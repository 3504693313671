import React from 'react';
import { connect } from 'react-redux';

import type { ReduxStore } from '../../redux/reducers/index';
import type { RequestStatus } from './reducer';
import type { ReactNode } from 'react';

import { getRequestStatus } from './reducer';

type RenderProps<TPropsAfterConnect> = TPropsAfterConnect & RequestStatus;

export type DataConnector<T> = (
  state: ReduxStore,
  requestStatus: RequestStatus
) => T;
export type DataRenderer<T> = (props: RenderProps<T>) => ReactNode;

type RequestConnectorProps<TProvidedProps> = {
  connect: DataConnector<TProvidedProps>;
  render: DataRenderer<TProvidedProps>;
  requestId: string;
  fetch: () => Promise<any>;
};

type RequestConnectorPropsAfterConnect<TProvidedProps> = {
  connectedProps: TProvidedProps;
  requestStatus: RequestStatus;
} & RequestConnectorProps<TProvidedProps>;

type State<T> = {
  previousConnectedProps: T | undefined | null;
};

class _RequestConnector<T> extends React.Component<
  RequestConnectorPropsAfterConnect<T>,
  State<T>
> {
  state: State<T> = {
    previousConnectedProps: null,
  };

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps: RequestConnectorPropsAfterConnect<T>) {
    // We save the previous value for "overlay" effects
    if (
      nextProps.requestStatus.isFetching &&
      !this.props.requestStatus.isFetching
    ) {
      this.setState({ previousConnectedProps: this.props.connectedProps });
    }
  }

  render() {
    const { previousConnectedProps } = this.state;
    const { requestStatus, connectedProps, render, fetch } = this.props;

    const { requestId, isFetching, hasError, noContent } = requestStatus;

    const renderedConnectedProps = isFetching
      ? previousConnectedProps
      : connectedProps;

    // @ts-expect-error: TSFIXE if you have the courage
    return render({
      ...renderedConnectedProps,
      requestId,
      isFetching,
      hasError,
      noContent,
      refetchData: fetch,
    });
  }
}

function mapStateToProps<T>(
  state: ReduxStore,
  { connect, requestId }: RequestConnectorProps<T>
): Omit<RequestConnectorPropsAfterConnect<T>, keyof RequestConnectorProps<T>> {
  const requestStatus = getRequestStatus(state, requestId);

  return {
    connectedProps: connect(state, requestStatus),
    requestStatus,
  };
}

export const RequestConnector = connect(mapStateToProps)(
  _RequestConnector
) as React.ComponentType<RequestConnectorProps<any>>;
