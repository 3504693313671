import React from 'react';
import { useNavigate } from 'react-router-dom';

import { SurveyCampaign } from 'models';

import useFeatureFlags from 'helpers/hooks/useFeatureFlags';
import { __ } from 'helpers/i18n';
import { pathToSurveyCampaignConversations } from 'helpers/paths';

import { EmptyStateWithIcon } from 'components';
import { ActiveFilters } from 'components/Filters/types';

import Header from '../components/Header';
import List from './List';

type Props = {
  campaign: SurveyCampaign;
  selectedPeriodId: string;
  userFilters: ActiveFilters | '';
  onUserFiltersChange: (newQueryParams: '' | ActiveFilters) => void | undefined;
};

const Conversations = ({
  campaign,
  selectedPeriodId,
  userFilters,
  onUserFiltersChange,
}: Props) => {
  const navigate = useNavigate();
  const featureFlags = useFeatureFlags();
  const filters = featureFlags.surveyFilteringDisabled ? '' : userFilters;
  const selectedPeriod = campaign.pollingPeriods.find(
    period => period.id === selectedPeriodId
  );
  const handleSelectedPeriodChange = periodId => {
    navigate(pathToSurveyCampaignConversations(campaign.id, periodId));
  };

  if (campaign.pollingPeriods.length === 0) {
    return (
      <EmptyStateWithIcon
        iconName="comment"
        title={__(
          "You don't have any follow-up conversations yet for this survey."
        )}
      />
    );
  }

  return (
    <>
      <Header
        campaign={campaign}
        selectedPeriod={selectedPeriod}
        handleSelectedPeriodChange={handleSelectedPeriodChange}
        userFilters={filters}
        onUserFiltersChange={onUserFiltersChange}
      />
      <List
        campaign={campaign}
        periodId={selectedPeriod?.id}
        userFilters={filters}
        additionalQueryParams={{
          sort: { lastMessageAt: 'desc' },
        }}
      />
    </>
  );
};

export default Conversations;
