import React, { useContext } from 'react';

import {
  SurveyCampaign,
  SurveyPollingPeriod,
  SurveyQuestionStats,
} from 'models';

import can from 'helpers/can';
import useFeatureFlags from 'helpers/hooks/useFeatureFlags';
import { __, date } from 'helpers/i18n';

import {
  Icon,
  Level,
  LevelItem,
  LevelLeft,
  LevelRight,
  PeriodPicker,
  StrictlySanitizedHtml,
  Text,
} from 'components';
import { ActiveFilters } from 'components/Filters/types';

import UserMultiFilters from 'scenes/components/UserMultiFilters';
import { surveyIsRecurrent } from 'scenes/surveys/helpers/survey.helpers';

import { DataContext } from '../index';

type Props = {
  campaign: SurveyCampaign;
  handleSelectedPeriodChange: (periodId: string) => void;
  selectedPeriod: SurveyPollingPeriod | undefined;
  questionStats?: SurveyQuestionStats;
  userFilters: ActiveFilters | '';
  onUserFiltersChange: (newQueryParams: '' | ActiveFilters) => void | undefined;
};

const SurveyDetailedResultsHeader = ({
  campaign,
  selectedPeriod,
  handleSelectedPeriodChange,
  questionStats,
  userFilters,
  onUserFiltersChange,
}: Props) => {
  const { maxFiltersCount } = useContext(DataContext);
  const surveyRecurrentWithPeriod =
    surveyIsRecurrent(campaign) && !!selectedPeriod;
  const activePeriod = !!(
    questionStats?.periodIsActive || selectedPeriod?.active
  );
  const formattedEndDate = date(
    questionStats ? questionStats.periodEndDate : selectedPeriod?.endDate,
    'dddd LL'
  );
  const featureFlags = useFeatureFlags();

  const message = activePeriod
    ? __(
        'The evaluation of this period is under way and will close on <b>%1</b>',
        formattedEndDate
      )
    : __(
        'The evaluation of this period has been closed since <b>%1</b>',
        formattedEndDate
      );

  const campaignPeriods =
    questionStats?.correlatedPollingPeriods || campaign.pollingPeriods;
  const periods = !campaignPeriods
    ? []
    : campaignPeriods.map(period => {
        const disabled = !can({
          perform: 'stats',
          on: period,
        });
        return {
          id: period.id,
          name: period.displayedName,
          disabled,
          tooltipMessage: disabled
            ? __(
                'There are no collaborators from your entity within this period.'
              )
            : undefined,
        };
      });

  return (
    <Level className="mb-6">
      <LevelLeft>
        {surveyRecurrentWithPeriod && (
          <LevelItem>
            <PeriodPicker
              period={{
                id: selectedPeriod.id,
                name: selectedPeriod.displayedName,
              }}
              periods={periods}
              isDisabled={!campaignPeriods || campaignPeriods.length < 2}
              onChange={handleSelectedPeriodChange}
            />
          </LevelItem>
        )}
        {!featureFlags.surveyFilteringDisabled && (
          <LevelItem>
            <UserMultiFilters
              onChange={({ userFilters }) => onUserFiltersChange(userFilters)}
              userFilters={userFilters}
              filteredSource="survey_users"
              maxFiltersCount={maxFiltersCount}
            />
          </LevelItem>
        )}
      </LevelLeft>
      <LevelRight>
        <Text color="info" preset="14s6">
          <Icon name="timer" className="mr-1" />
          <StrictlySanitizedHtml html={message} />
        </Text>
      </LevelRight>
    </Level>
  );
};

export default SurveyDetailedResultsHeader as React.ComponentType<Props>;
