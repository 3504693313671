import React from 'react';

import classNames from 'helpers/classNames';
import { useActiveUser, useAppDispatch, useSession } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import { pathToUser } from 'helpers/navigation';

import { signOut } from 'redux/actions';
import { stopImpersonation } from 'redux/actions/resources';

import {
  Dropdown,
  DropdownMenu,
  DropdownTrigger,
  FeatureFlagged,
} from 'components';
import Avatar from 'components/Avatar';
import Icon from 'components/Icon';
import { Text } from 'components/text';

import NavItem from '../NavItem';

type Props = {
  className?: string;
};

function NavProfile({ className }: Props) {
  const activeUser = useActiveUser();
  const { isImpersonated } = useSession();
  const dispatch = useAppDispatch();

  const signOutMenuItem = (
    <NavItem
      testClassName="test-user-nav-profile-action-log-out"
      className="rounded-none"
      title={__('Sign out')}
      onClick={() => dispatch(signOut())}
    />
  );

  const stopImpersonationMenuItem = (
    <NavItem
      className="rounded-none"
      title={__('Stop impersonation')}
      onClick={() => dispatch(stopImpersonation())}
    />
  );

  return (
    <div
      className={classNames(
        className,
        'text-nav-text border-0 border-solid p-2 md:border-t border-nav-border-default bg-nav-bg'
      )}
    >
      <Dropdown align="right" style={{ display: 'block' }}>
        <DropdownTrigger
          testClassName="test-user-nav-profile-actions"
          className="cursor-pointer p-2 md:hover:bg-nav-item-hover-bg md:focus-within:!bg-nav-item-focus-bg"
          testableStyle={{ width: '100%' }}
        >
          <Avatar
            url={activeUser.avatarUrl}
            avatarChildrenClassName="w-full"
            className="mr-1 w-full"
          >
            <div className="items-center w-full hidden md:flex">
              <Text
                className="ml-1 pr-2 font-semibold flex-grow"
                overflowEllipsis
                size={6}
              >
                {activeUser.fullName}
              </Text>
              <Icon
                className="text-nav-icon-default"
                iconStyle={{ fontSize: 20 }}
                name="expand_more"
              />
            </div>
          </Avatar>
        </DropdownTrigger>
        <DropdownMenu contentClassName="!bg-nav-bg !rounded-lg border-nav-border-default !py-2">
          <NavItem
            className="rounded-none"
            title={__('Profile')}
            path={pathToUser(activeUser.id)}
          />
          <FeatureFlagged flag="jimoChangelog">
            <NavItem
              className="rounded-none"
              iconName="open_in_new"
              additionalClassName="flex-row-reverse"
              title={__("What's new?")}
              onClick={() => {
                // @ts-ignore - jimo is a global variable
                const uuid = window.jimo?.push(['get', 'jimer:id']);

                if (uuid) {
                  window.open(
                    `https://project-3qoai4.usesjimo.com/feed?jimerId=${uuid}`,
                    '_blank'
                  );
                }
              }}
            />
          </FeatureFlagged>
          {isImpersonated ? stopImpersonationMenuItem : signOutMenuItem}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
}

export default NavProfile;
