import React, { useEffect } from 'react';
import { useState } from 'react';
import { useBlocker } from 'react-router-dom';

import { __ } from 'helpers/i18n';

import { ConfirmationModal } from 'components';

type Props = {
  isDirty: boolean;
  onSubmit: () => void;
};

const UnsavedModificationsGuard = ({ isDirty, onSubmit }: Props) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  let blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      isDirty && currentLocation.pathname !== nextLocation.pathname
  );

  useEffect(() => {
    if (blocker.state !== 'blocked') {
      return;
    }
    setShowModal(true);
  }, [blocker.state]);

  useEffect(() => {
    // Set up the beforeunload event to handle browser-level navigation
    const handleBeforeUnload = event => {
      if (isDirty) {
        event.preventDefault();
        event.returnValue = ''; // Most browsers require this to show the dialog
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isDirty]);

  useEffect(() => {
    // Set up the popstate event to handle back button navigation
    const handlePopState = event => {
      if (isDirty) {
        event.preventDefault();
        setShowModal(true);
      }
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [isDirty]);

  const handleConfirm = () => {
    setShowModal(false);
    onSubmit();
    blocker.proceed?.();
  };

  const handleCancel = () => {
    setShowModal(false);
    blocker.proceed?.();
  };

  const onModalClose = () => {
    setShowModal(false);
    blocker.reset?.();
  };

  if (!showModal) return null;

  return (
    <ConfirmationModal
      onConfirm={handleConfirm}
      confirmLabel={__('Save and exit')}
      onCancel={handleCancel}
      cancelLabel={__('Leave without saving')}
      title={__('Unsaved changes')}
      refreshContentOnOpening={false}
      isActive={showModal}
      onClose={onModalClose}
    >
      <p>
        {__(
          'You have unsaved changes. If you leave this page now, your changes will be lost.'
        )}
      </p>
      <p>{__('Are you sure you want to continue?')}</p>
    </ConfirmationModal>
  );
};

export default UnsavedModificationsGuard;
