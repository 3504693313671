import React, { CSSProperties, useState } from 'react';

import type {
  UserFilterSegment,
  UserFilterableFieldType,
  UserFilterableSource,
} from 'models';

import { __ } from 'helpers/i18n';

import { Button, Centered, Icon, Link } from 'components';

import UserFilterSegmentPicker from 'scenes/components/UserFilterSegmentPicker';

type Props = {
  filterType: UserFilterableFieldType;
  filteredSource?: UserFilterableSource;
  segment: UserFilterSegment | undefined | null;
  onSubmit: (userFilter?: UserFilterSegment | null) => void;
  onBack: () => void;
  style?: CSSProperties;
};

const SelectedFilter = ({
  segment: segmentFromProps,
  filterType,
  filteredSource,
  onSubmit,
  onBack,
  style,
}: Props) => {
  const [segment, setSegment] = useState<UserFilterSegment | undefined | null>(
    segmentFromProps
  );

  return (
    <div className="selected-filter" style={style}>
      <Link
        overriddenClassName="back-button"
        onClick={onBack}
        style={{ marginLeft: -4 }}
      >
        <Icon style={{ marginBottom: 2 }} name="keyboard_arrow_left" />
        <span>{__('back')}</span>
      </Link>

      <UserFilterSegmentPicker
        filterType={filterType}
        filteredSource={filteredSource}
        segment={segment}
        onChange={setSegment}
        style={{ marginTop: 16 }}
      />

      <Centered style={{ marginTop: 16 }}>
        <Button
          color="primary"
          onClick={() => onSubmit(segment)}
          disabled={
            !segment || (!!segment && segment.filterType !== filterType.slug)
          }
        >
          {__('Apply')}
        </Button>
      </Centered>
    </div>
  );
};

export default SelectedFilter;
