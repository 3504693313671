import { useMemo } from 'react';

import { useCurrentOrganization } from './useCurrentOrganization';

/**
 * @example
 * const featureFlags = useFeatureFlags();
 *
 * if (featureFlags.revampProfile) {
 *   // ...
 * }
 */
const useFeatureFlags = () => {
  const { featureFlags } = useCurrentOrganization();

  // Faster lookup on re-renders
  const indexedFeatureFlags = useMemo(
    () =>
      featureFlags.reduce((acc, featureFlag) => {
        acc[featureFlag] = true;
        return acc;
      }, {} as Record<string, boolean>),
    [featureFlags]
  );

  // Return `true | false` instead of `true | undefined`
  const featureFlagsProxy = useMemo(
    () =>
      new Proxy(indexedFeatureFlags, {
        get: function (target, name: string) {
          return target[name] || false;
        },
      }),
    [indexedFeatureFlags]
  );

  return featureFlagsProxy;
};

export default useFeatureFlags;
