import moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import type { Meeting, User } from 'models';
import type { AppDispatch } from 'redux/actions/types';

import { handleFormErrors } from 'helpers/api';
import { guessTimeZone } from 'helpers/date';
import { __, n__, toSentence } from 'helpers/i18n';
import transformProps from 'helpers/transformProps';

import { del, put } from 'redux/actions/api';

import {
  Button,
  Control,
  DatePicker,
  DurationPicker,
  FieldError,
  Label,
  Link,
  ModalCard,
  ModalCardBody,
  ModalCardFooter,
  ModalCardHead,
  ModalCardTitle,
  PullRight,
  TimePicker,
} from 'components';

import ScheduleMeetingModalHelper from './ScheduleMeetingModalHelper';

type MeetingParams = {
  startsAt: string;
  durationInMinutes: number;
  timezone: string;
};

type Props = {
  userReviewId: string;
  meeting: Meeting;
  isActive: boolean;
  onClose: () => void;
};

type AfterConnectProps = Props & {
  participantsWithEmail: Array<User>;
  participantsWithoutEmail: Array<User>;
  onUpdateMeeting: (meeting: MeetingParams) => Promise<any>;
  onDeleteMeeting: () => Promise<any>;
};

type State = {
  date: Date | null;
  time: Date | null;
  durationInMinutes: number;
  dirty: boolean;
  errors: { startsAt?: string };
};

class ScheduleMeetingModal extends React.Component<AfterConnectProps, State> {
  constructor(props) {
    super(props);

    this.state = {
      ...this.getStateFromMeeting(props.meeting),
      dirty: false,
      errors: {},
    };
  }

  getEmptyState = () => ({
    date: null,
    time: null,
    durationInMinutes: 30,
  });

  getStateFromMeeting = (meeting: Meeting) => {
    if (!meeting.isScheduled) return this.getEmptyState();

    const startsAt = new Date(meeting.startsAt);

    return {
      date: startsAt,
      time: startsAt,
      durationInMinutes: meeting.durationInMinutes || 30,
    };
  };

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps({ meeting: nextMeeting }) {
    if (nextMeeting.updatedAt !== this.props.meeting.updatedAt) {
      this.setState({ ...this.getStateFromMeeting(nextMeeting), dirty: false });
    }
  }

  emptyState = () =>
    this.setState({
      ...this.getEmptyState(),
      dirty: false,
    });

  resetStateToMeeting = () =>
    this.setState({
      ...this.getStateFromMeeting(this.props.meeting),
      dirty: false,
    });

  onModalClose = () => {
    this.resetStateToMeeting();
    this.props.onClose();
  };

  setDate = (date: Date | null) => this.setState({ date, dirty: true });

  setTime = (time: Date | null) => {
    this.setState({ time, dirty: true });
  };

  setDurationInMinutes = (durationInMinutes: number) =>
    this.setState({ durationInMinutes, dirty: true });

  onSubmit = async () => {
    const { onUpdateMeeting, onClose } = this.props;
    const { date, time, durationInMinutes } = this.state;
    if (!date || !time || !durationInMinutes) return;

    const startsAt = moment(date)
      .hours(moment(time).hours())
      .minutes(moment(time).minutes());

    await handleFormErrors(
      async () => {
        await onUpdateMeeting({
          startsAt: startsAt.format(),
          durationInMinutes,
          timezone: guessTimeZone(),
        });

        onClose();
      },
      errors => this.setState({ errors })
    );
  };

  render() {
    const {
      meeting,
      onDeleteMeeting,
      isActive,
      onClose,
      participantsWithEmail,
      participantsWithoutEmail,
    } = this.props;
    const { date, time, durationInMinutes, dirty, errors } = this.state;
    const canSubmit = !!date && !!time && !!durationInMinutes && dirty;

    return (
      <ModalCard
        isActive={isActive}
        refreshContentOnOpening={false}
        onClose={this.onModalClose}
      >
        <ModalCardHead onClose={this.onModalClose}>
          <ModalCardTitle>
            {meeting.isScheduled
              ? __('Update the meeting')
              : __('Schedule a meeting')}
          </ModalCardTitle>
        </ModalCardHead>

        <ModalCardBody>
          <p style={{ marginBottom: 10 }}>
            {__(
              'Select the time and date for the in person meeting. You will be able to edit/delete this meeting later on.'
            )}
          </p>

          <div className="flex gap-4 mb-6">
            <div>
              <Label className="mb-0" color="soften">
                {__('Date')}
              </Label>

              <Control>
                <DatePicker
                  placeholder={'01/01/2020'}
                  value={date}
                  // @ts-ignore TSFIXME: Fix strictNullChecks error
                  onChange={this.setDate}
                  withPortal
                  name="elevo_meeting_date"
                />
              </Control>

              {!!errors && errors.startsAt && (
                <FieldError>{errors.startsAt}</FieldError>
              )}
            </div>

            <div>
              <Label className="mb-0" color="soften">
                {__('Time')}
              </Label>

              <Control>
                <TimePicker
                  value={time}
                  // @ts-ignore TSFIXME: Fix strictNullChecks error
                  onChange={this.setTime}
                  withPortal
                  name="elevo_meeting_time"
                />
              </Control>
            </div>

            <div>
              <Label className="mb-0" color="soften">
                {__('Duration')}
              </Label>

              <Control>
                <DurationPicker
                  placeholder={__('45 min')}
                  value={durationInMinutes}
                  onChange={this.setDurationInMinutes}
                  name="elevo_meeting_duration"
                />
              </Control>
            </div>
          </div>

          {meeting.isScheduled && (
            <PullRight style={{ marginBottom: 16 }}>
              <Link
                overriddenClassName="has-text-danger"
                onClick={async () => {
                  await onDeleteMeeting();
                  this.emptyState();
                  onClose();
                }}
              >
                {__('Cancel meeting')}
              </Link>
            </PullRight>
          )}
          <ScheduleMeetingModalHelper
            isScheduled={meeting.isScheduled}
            participantsWithEmail={participantsWithEmail}
            participantsWithoutEmail={participantsWithoutEmail}
          />
        </ModalCardBody>

        <ModalCardFooter>
          <Button color="primary" onClick={this.onSubmit} disabled={!canSubmit}>
            {meeting.isScheduled
              ? __('Update meeting')
              : __('Schedule meeting')}
          </Button>
          <Button color="secondary" onClick={this.onModalClose}>
            {__('Cancel')}
          </Button>
        </ModalCardFooter>
      </ModalCard>
    );
  }
}

const propsTransformer = ({ meeting: { participants } }: Props) => {
  return {
    participantsWithEmail: participants.filter(
      user => user.email.toString() !== ''
    ),
    participantsWithoutEmail: participants.filter(
      user => user.email.toString() === ''
    ),
  };
};

const mapDispatchToProps = (
  dispatch: AppDispatch,
  { userReviewId, meeting: { participants } }: Props
) => {
  const participantsWithEmail = participants.filter(
    user => user.email.toString() !== ''
  );
  const withEmailFullNames =
    participantsWithEmail.length > 0
      ? toSentence(participantsWithEmail.map(({ fullName }) => fullName))
      : '';
  return {
    onUpdateMeeting: (meeting: MeetingParams) =>
      dispatch(
        put(
          `user_reviews/${userReviewId}/meeting`,
          { meeting },
          {
            successMessage: n__(
              'A calendar invitation has been sent to %2.',
              'A calendar invitation has been sent to %2.',
              participantsWithEmail.length,
              withEmailFullNames
            ),
          }
        )
      ),
    onDeleteMeeting: () =>
      dispatch(
        del(`user_reviews/${userReviewId}/meeting`, undefined, {
          successMessage: n__(
            'A calendar cancelation has been sent to %2.',
            'A calendar cancelation has been sent to %2.',
            participantsWithEmail.length,
            withEmailFullNames
          ),
        })
      ),
  };
};

export default compose<React.ComponentType<Props>>(
  transformProps(propsTransformer),
  // @ts-expect-error TSFIXME: connect/mapDispatch don't work because our Action is wrongly typed (missing ThunkAction)
  connect(null, mapDispatchToProps)
)(ScheduleMeetingModal);
