import React from 'react';

import { __ } from 'helpers/i18n';

import { ContentContainer, DesignSystem, PageHeader } from 'components';

import IntegrationCreator from './IntegrationCreator';
import IntegrationList from './IntegrationList';

const Integrations = () => (
  <DesignSystem version={2}>
    {/* eslint-disable-next-line react/jsx-key */}
    <PageHeader title={__('Integrations')} actions={[<IntegrationCreator />]} />
    <ContentContainer>
      <IntegrationList />
    </ContentContainer>
  </DesignSystem>
);

export default Integrations;
