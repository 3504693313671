import { debounce } from 'lodash';
import React, { ReactNode } from 'react';
import { connect } from 'react-redux';

import type { ReduxStore } from 'redux/reducers';

import {
  type AutoSaveStatus,
  getAutoSaveStatus,
  getFailedFieldsCount,
} from 'redux/reducers/autoSave';

type DelayedAction = () => void;

type Props = {
  render: (args: {
    autoSaveStatus: AutoSaveStatus;
    failedFieldsCount: number | null | undefined;
    delayedAction: DelayedAction | null;
    setDelayedAction: (delayedAction: DelayedAction | null) => void;
  }) => ReactNode;
  fieldUid?: string;
  debounceDelay?: number;
};

type AfterConnectProps = Props & {
  autoSaveStatus: AutoSaveStatus;
  failedFieldsCount: number | null | undefined;
};

type State = {
  autoSaveStatus: AutoSaveStatus;
  failedFieldsCount: number | null | undefined;
  delayedAction: DelayedAction | null;
};

class _Connector extends React.Component<AfterConnectProps, State> {
  state: State = {
    autoSaveStatus: this.props.autoSaveStatus,
    failedFieldsCount: this.props.failedFieldsCount,
    delayedAction: null,
  };

  updateState = debounce(
    (newState: State) => {
      this.setState(newState);
    },
    this.props.debounceDelay,
    {
      leading: true,
    }
  );

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps: AfterConnectProps) {
    const nextState = {
      ...this.state,
      autoSaveStatus: nextProps.autoSaveStatus,
      failedFieldsCount: nextProps.failedFieldsCount,
    };

    if (!!this.props.debounceDelay) {
      this.updateState(nextState);
    } else {
      this.setState(nextState);
    }
  }

  render() {
    const { autoSaveStatus, failedFieldsCount, delayedAction } = this.state;

    if (delayedAction) {
      if (autoSaveStatus === 'saved') {
        this.setDelayedAction(null);
        delayedAction();
      } else if (autoSaveStatus === 'failed') {
        this.setDelayedAction(null);
      }
    }

    return this.props.render({
      autoSaveStatus,
      failedFieldsCount,
      delayedAction,
      setDelayedAction: this.setDelayedAction.bind(this),
    });
  }

  setDelayedAction(delayedAction: DelayedAction | null) {
    this.setState({ ...this.state, delayedAction });
  }
}

function mapStateToProps(state: ReduxStore, { fieldUid }: Props) {
  return {
    autoSaveStatus: getAutoSaveStatus(state.autoSave, fieldUid),
    failedFieldsCount: fieldUid ? null : getFailedFieldsCount(state.autoSave),
  };
}

export default connect(
  mapStateToProps,
  null
)(_Connector) as React.ComponentType<Props>;
