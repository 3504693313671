import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { SurveyCampaign } from 'models';

import useFeatureFlags from 'helpers/hooks/useFeatureFlags';
import invariant from 'helpers/invariant';
import queryString from 'helpers/queryString';

import { ActiveFilters } from 'components/Filters/types';

import Header from '../components/Header';
import FormStats from './FormStats';
import StatsBox from './StatsBox';
import NoPeriodEmptyStateStats from './StatsBox/NoPeriodEmptyState';

type Props = {
  surveyCampaign: SurveyCampaign;
  periodId: string | null;
  userFilters: ActiveFilters | '';
  onUserFiltersChange: (newQueryParams: '' | ActiveFilters) => void | undefined;
};

const Overview = ({
  surveyCampaign,
  periodId,
  userFilters,
  onUserFiltersChange,
}: Props) => {
  const featureFlags = useFeatureFlags();
  const filters = featureFlags.surveyFilteringDisabled ? '' : userFilters;
  const navigate = useNavigate();
  const location = useLocation();
  const { lastPeriodId, anonymous } = surveyCampaign;
  const [selectedPeriodId, setSelectedPeriodId] = useState(
    periodId?.toString() || lastPeriodId?.toString()
  );

  if (!lastPeriodId)
    return <NoPeriodEmptyStateStats campaign={surveyCampaign} />;

  const selectedPeriod = surveyCampaign.pollingPeriods.find(
    period => period.id === selectedPeriodId
  );
  const handlePeriodIdChange = (periodId: string) => {
    setSelectedPeriodId(periodId);
    const params = {
      ...queryString.parse(window.location.search),
      periodId: periodId,
    };
    const newSearch = queryString.stringify(params);
    navigate(`${location.pathname}?${newSearch}`, { replace: true });
  };
  invariant(
    selectedPeriod,
    'Selected period must exist in campaign polling periods.'
  );

  return (
    <>
      <Header
        campaign={surveyCampaign}
        selectedPeriod={selectedPeriod}
        handleSelectedPeriodChange={handlePeriodIdChange}
        userFilters={filters}
        onUserFiltersChange={onUserFiltersChange}
      />
      <StatsBox
        campaignId={surveyCampaign.id}
        isAnonymous={anonymous}
        periodId={selectedPeriod.id}
        userFilters={filters}
      />
      <FormStats
        formId={selectedPeriod.formId}
        periodId={selectedPeriod.id}
        campaignId={surveyCampaign.id}
        userFilters={filters}
      />
    </>
  );
};

export default Overview;
