import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import can from 'helpers/can';
import { useAppSelector, useCurrentOrganization } from 'helpers/hooks';
import { useOrganizationPlan } from 'helpers/hooks';
import { pathToHome } from 'helpers/paths';

import { getCurrentOrganization, getCurrentUser } from 'redux/reducers';

import { BreadcrumbAnchor } from 'components';

import Homepage from 'scenes/Homepage';
import Callback from 'scenes/authentication/Callback';
import ForgotPassword from 'scenes/authentication/ForgotPassword';
import NotSignedInRedirect from 'scenes/authentication/NotSignedInRedirect';
import SignIn from 'scenes/authentication/SignIn';
import ValidateAccessCode from 'scenes/authentication/SignIn/ValidateAccessCode';
import AppLayout from 'scenes/components/AppLayout';

import SkillsRoutes from '././skills/Routes';
import EmailNotificationsDisabledModal from './EmailNotificationsDisabledModal';
import MyTeam from './MyTeam';
import RedirectToDownload from './RedirectToDownload';
import RequireUnauth from './RequiredUnauth';
import ServerErrorPage from './ServerErrorPage';
import UserProfile from './UserProfile';
import AdminRoutes from './admin/Routes';
import TemplatePreview from './admin/reviewTemplates/Template/Preview';
import AuditsRoutes from './audits/Routes';
import AcceptInvitation from './authentication/AcceptInvitation';
import ResetPassword from './authentication/ResetPassword';
import CompensationRoutes from './compensation/Routes';
import ManagerCycleIndex from './cycles';
import ObjectivesRoutes from './objectives/Routes';
import PeopleReviewRoutes from './peopleReview/Routes';
import Evaluation from './review/Evaluation';
import ReviewsRoutes from './reviews/Routes';
import ServiceRoutes from './service/Routes';
import SurveyRoutes from './surveys/Routes';
import TrainingsRedirectRoutes from './trainings/RedirectRoutes';
import TrainingsRoutes from './trainings/Routes';

/*
  Routes displayed inside the main app, the app bar being displayed
*/

const AppRoutes = () => {
  const organization = useCurrentOrganization();
  const plan = useOrganizationPlan();
  const canAccessToAuditReports = can({
    perform: 'show_audit_reports_menu',
    on: organization,
  });

  return (
    <AppLayout>
      <EmailNotificationsDisabledModal />
      <BreadcrumbAnchor name="user_review_list" defaultTo={pathToHome()} />
      <Routes>
        <Route index element={<Navigate to="welcome" />} />
        <Route path="welcome" element={<Homepage />} />
        <Route path="redirect" element={<RedirectToDownload />} />
        <Route path="reviews/*" element={<ReviewsRoutes />} />
        <Route path="cycles/:id/*" element={<ManagerCycleIndex />} />

        <Route path="admin/*" element={<AdminRoutes />} />
        <Route path="evaluations/:evaluationId" element={<Evaluation />} />
        <Route path="objectives/*" element={<ObjectivesRoutes />} />
        <Route path="my-team" element={<MyTeam />} />
        <Route path="user/:id/*" element={<UserProfile />} />
        <Route path="surveys/*" element={<SurveyRoutes />} />
        {plan.skillsAndCareersEnabled && (
          <Route path="skills/*" element={<SkillsRoutes />} />
        )}
        {can({
          perform: 'show_people_reviews_menu',
          on: organization,
        }) && <Route path="people-review/*" element={<PeopleReviewRoutes />} />}
        <Route path="trainings/*" element={<TrainingsRoutes />} />
        {/* Backward compatibility after moving /admin/trainings to /trainings/plan */}
        <Route path="admin/trainings" element={<TrainingsRedirectRoutes />} />

        {canAccessToAuditReports && (
          <Route path="audits/*" element={<AuditsRoutes />} />
        )}
        <Route path="compensation/*" element={<CompensationRoutes />} />
        {/* This allow users with preview url to see it */}
        <Route
          path="admin/templates/:id/preview/v2/:role"
          element={<TemplatePreview />}
        />
      </Routes>
    </AppLayout>
  );
};
/*
  Base level routes
*/

export const MainRoutes = () => {
  const activeUser = useAppSelector(state => getCurrentUser(state));
  const organization = useAppSelector(state => getCurrentOrganization(state));

  const loggedIn = activeUser && organization;

  return (
    <Routes>
      <Route path="/service/*" element={<ServiceRoutes />} />
      {!loggedIn && (
        <>
          <Route path="/access_code" element={<ValidateAccessCode />} />
          <Route path="/forgot_password" element={<ForgotPassword />} />
          <Route path="/auth/notsignedin" element={<NotSignedInRedirect />} />
        </>
      )}
      <Route path="/signin" element={<SignIn />} />
      <Route path="/auth/callback" element={<Callback />} />
      <Route
        path="/o/:organizationShortName/accept_invitation"
        element={<AcceptInvitation />}
      />
      {loggedIn && (
        <Route
          path="*"
          element={<Navigate to={`/o/${organization.shortName}/welcome`} />}
        />
      )}
      {!loggedIn && <Route path="*" element={<Navigate to={'/signin'} />} />}
      <Route
        path="/o/:organizationShortName/reset_password"
        element={<ResetPassword />}
      />
      <Route element={<RequireUnauth loggedIn={loggedIn} />}>
        <Route path="/o/:organizationShortName/*" element={<AppRoutes />} />
      </Route>
      {/* eslint-disable-next-line react/no-children-prop */}
      <Route path="/500" element={<ServerErrorPage />} />
    </Routes>
  );
};
