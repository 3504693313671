import React, { ComponentType } from 'react';
import { useParams } from 'react-router-dom';

export const withMatchParams = <P extends object>(
  WrappedComponent: ComponentType<
    P & { match: Record<string, string | undefined> }
  >
) => {
  // eslint-disable-next-line react/display-name
  return (props: P) => {
    const params = useParams();
    return <WrappedComponent {...props} match={params} />;
  };
};
