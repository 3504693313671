import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import type { AttributesOnlyOrganization } from 'models';

import {
  useActiveUser,
  useAppDispatch,
  useCurrentOrganization,
  useOrganizationPlan,
} from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import { pathToUser } from 'helpers/navigation';
import { trackAction } from 'helpers/tracking';

import { post } from 'redux/actions/api';
import { htmlErrorNotice } from 'redux/actions/application';

import { hasShape, numberType, unknownType } from 'types/predicates/WithShape';

import {
  Button,
  Can,
  Checkbox,
  Control,
  Field,
  FieldError,
  Helper,
  Input,
  ModalCard,
  ModalCardBody,
  ModalCardFooter,
  ModalCardHead,
  ModalCardTitle,
  SupportLink,
  Text,
} from 'components';

import EntityPicker from 'scenes/components/EntityPicker';

type Props = {
  withUsername: boolean;
  onClose: () => void;
};

type InviteParams = {
  firstName: string;
  lastName: string;
  entityId: string;
  external: boolean;
  email?: string;
  username?: string;
};

type Errors = {
  firstName?: string;
  lastName?: string;
  email?: string;
  external?: string;
  username?: string;
};

const successMessage = (
  organization: AttributesOnlyOrganization,
  { email, username, external }: InviteParams
) => {
  const login = email || username;

  const message = __(
    '<b>%1 was successfully added to join %2!</b>',
    login,
    organization.name
  );

  if (email && (!organization.ssoEnabled || external)) {
    return (
      message +
      __(
        '<br />An invitation email will be sent when %1 is added to a review campaign.',
        login
      )
    );
  }

  if (!organization.ssoEnabled || external) {
    return (
      message +
      __(
        '<br />You can generate an access code for this employee to activate their profile.'
      )
    );
  }

  return message;
};

const InviteUserModal = ({ withUsername, onClose }: Props) => {
  const navigate = useNavigate();
  const organization = useCurrentOrganization();
  const organizationPlan = useOrganizationPlan();
  const activeUser = useActiveUser();

  const dispatch = useAppDispatch();
  const postUserInvite = async (props: InviteParams) => {
    const response = await dispatch(
      post('users', props, {
        withDefaultErrorHandling: false,
        successMessage: successMessage(organization, props),
      })
    );
    trackAction('Single user created', {
      // eslint-disable-next-line react/prop-types
      firstNameIsDefined: !!props.firstName,
      // eslint-disable-next-line react/prop-types
      lastNameIsDefined: !!props.lastName,
    });

    return response.response.body.data.id;
  };
  const notifyError = (msg: string) => dispatch(htmlErrorNotice(msg));

  const [attributes, setAttributes] = useState<InviteParams>({
    firstName: '',
    lastName: '',
    email: undefined,
    username: undefined,
    entityId: '',
    external: false,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState<Errors>({});

  const setFirstName = (firstName: string) =>
    setAttributes(prevState => ({ ...prevState, firstName }));

  const setLastName = (lastName: string) =>
    setAttributes(prevState => ({ ...prevState, lastName }));

  const setEmail = (email: string) =>
    setAttributes(prevState => ({ ...prevState, email: email || undefined }));

  const setUsername = (username: string) =>
    setAttributes(prevState => ({
      ...prevState,
      username: username || undefined,
    }));

  const setEntityId = (entityId: string | null | undefined) =>
    setAttributes(prevState => ({ ...prevState, entityId: entityId || '' }));

  const setExternal = (external: boolean) =>
    setAttributes(prevState => ({ ...prevState, external }));

  const handleError = (error: unknown) => {
    setIsSubmitting(false);

    if (
      hasShape(error, { response: { statusCode: numberType } }) &&
      error.response.statusCode === 401
    ) {
      return;
    }

    const { email, username } = attributes;
    const errors = hasShape(error, {
      response: { body: { errors: unknownType } },
    })
      ? (error.response.body.errors as Errors)
      : undefined;

    if (
      errors &&
      (errors.firstName ||
        errors.lastName ||
        errors.email ||
        ((username || withUsername) && errors.username))
    ) {
      setErrors(errors);
    } else {
      window.logException(error);
      notifyError(
        __(
          '<b>There was an error and %1 could not be invited to join %2.</b><br />Try again or contact us at support@elevo.fr if the problem persists.',
          email || username || '',
          organization.name
        )
      );
    }
  };

  const inviteUser = async () => {
    setIsSubmitting(true);

    try {
      const newUserId = await postUserInvite(attributes);

      navigate(pathToUser(newUserId));
      onClose();
    } catch (e) {
      handleError(e);
    }
  };

  return (
    <ModalCard
      isActive={true}
      onClose={onClose}
      refreshContentOnOpening={false}
    >
      <ModalCardHead>
        <ModalCardTitle>{__('Add user')}</ModalCardTitle>
      </ModalCardHead>
      <ModalCardBody>
        <Helper>
          {__(
            '%1 directly for adding users in bulk.',
            <SupportLink>{__('Contact us')}</SupportLink>
          )}
        </Helper>

        {organization.syncIntegrationInfo.isEnabled && (
          <p className="-mt-4 mb-4">
            {__(
              "Please note that employees who are manually created won't be updated by your %1 integration.",
              organization.syncIntegrationInfo.name
            )}
          </p>
        )}

        <form
          onSubmit={e => {
            e.preventDefault();
            inviteUser();
          }}
        >
          <Field>
            <Control>
              <Input
                value={attributes.firstName}
                onChange={value => setFirstName(value)}
                placeholder={__('First name (optional)')}
                name="firstName"
              />
            </Control>
            {errors.firstName && <FieldError>{errors.firstName}</FieldError>}
          </Field>
          <Field>
            <Control>
              <Input
                value={attributes.lastName}
                onChange={value => setLastName(value)}
                placeholder={__('Last name (optional)')}
                name="lastName"
              />
            </Control>
            {errors.lastName && <FieldError>{errors.lastName}</FieldError>}
          </Field>
          {!withUsername && (
            <Field>
              <Control>
                <Input
                  value={attributes.email}
                  onChange={value => setEmail(value)}
                  placeholder={__('Email (mandatory)')}
                  name="email"
                />
              </Control>
              {errors.email && <FieldError>{errors.email[0]}</FieldError>}
            </Field>
          )}
          {organization.usernameEnabled && (
            <Field>
              <Control>
                <Input
                  value={attributes.username}
                  onChange={value => setUsername(value)}
                  placeholder={__(
                    'Username (%1)',
                    withUsername ? __('mandatory') : __('optional')
                  )}
                  name="username"
                />
              </Control>
              {errors.username && <FieldError>{errors.username[0]}</FieldError>}
            </Field>
          )}
          {organizationPlan.multiLevelAdminEnabled && (
            <Field>
              <EntityPicker
                selectedEntityId={attributes.entityId}
                onChange={id => setEntityId(id)}
                placeholder={__(
                  'Select an entity (%1)',
                  activeUser.isOrganizationAdmin
                    ? __('optional')
                    : __('mandatory')
                )}
                emptyStateRender={() => null}
              />
            </Field>
          )}
          <Can perform="create_external_users" on="organization">
            <Field>
              <Checkbox
                isChecked={attributes.external}
                onChange={value => setExternal(value)}
                label={
                  <Text>
                    <strong>{__('External user to the organization')}</strong>
                  </Text>
                }
                subLabel={
                  <Text color="info">
                    {__(
                      'External users can only participate as peers to 360 feedback'
                    )}
                  </Text>
                }
              />
            </Field>
          </Can>
          {/* This <button> is only for the Enter keypress to submit the form */}
          <button style={{ display: 'none' }} />
        </form>
      </ModalCardBody>
      <ModalCardFooter>
        <Button
          color="primary"
          onClick={inviteUser}
          isLoading={isSubmitting}
          disabled={withUsername ? !attributes.username : !attributes.email}
        >
          {__('Add user')}
        </Button>
        <Button color="secondary" onClick={onClose}>
          {__('Cancel')}
        </Button>
      </ModalCardFooter>
    </ModalCard>
  );
};

export default InviteUserModal;
