import React from 'react';

import { __ } from 'helpers/i18n';

import { Text } from 'components';

type Props = {
  isSigned: boolean;
  isNotSignedYet: boolean;
  isDisabled: boolean;
  withFinalComment: boolean;
  authorFullName: string;
};

export default function SignatureStatus({
  isSigned,
  isNotSignedYet,
  isDisabled,
  authorFullName,
  withFinalComment,
}: Props) {
  if (isDisabled && isSigned) return null;

  // eslint-disable-next-line react/prop-types
  const Status = ({ children }) => (
    <Text transformation="italic" weight="light" style={{ marginTop: 16 }}>
      {children}
    </Text>
  );

  if (isDisabled && isNotSignedYet) {
    return (
      <Status>{__('%1 has not signed the review yet', authorFullName)}</Status>
    );
  }

  if (isDisabled && withFinalComment) {
    return (
      <Status>
        {__('The final comment and signature are not yet available.')}
      </Status>
    );
  }

  if (isDisabled && !withFinalComment) {
    return <Status>{__('The signature is not yet available.')}</Status>;
  }

  return null;
}
