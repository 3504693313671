import React from 'react';

import type {
  TextAlign,
  TextSize,
  TextTransformation,
  TextWeight,
} from 'components/types/text';
import type { TextColor } from 'components/types/text';

import classNames from 'helpers/classNames';
import invariant from 'helpers/invariant';

import Link from '../links/Link';
import Editable, { type Props as EditableOptions } from './Editable';

export type TextPreset =
  | '32bs1'
  | '24bs2'
  | '18bs5'
  | '16bs5.5'
  | '14bs6'
  | '13bs7'
  | '13buppers7'
  | '16s5.5'
  | '14s6'
  | '13s7'
  | '11s8';

function propsForPreset(preset: TextPreset): {
  size: TextSize;
  weight?: TextWeight;
  transformation?: TextTransformation;
} {
  switch (preset) {
    case '32bs1':
      return { size: 1, weight: 'bold' };
    case '24bs2':
      return { size: 2, weight: 'semibold' };
    case '18bs5':
      return { size: 5, weight: 'semibold' };
    case '16bs5.5':
      return { size: 5.5, weight: 'semibold' };
    case '14bs6':
      return { size: 6, weight: 'semibold' };
    case '13bs7':
      return { size: 7, weight: 'semibold' };
    case '13buppers7':
      return { size: 7, weight: 'semibold', transformation: 'uppercase' };
    case '16s5.5':
      return { size: 5.5 };
    case '14s6':
      return { size: 6 };
    case '13s7':
      return { size: 7 };
    case '11s8':
      return { size: 8 };
    default:
      throw new Error(`Unknown Text preset ${JSON.stringify(preset)}`);
  }
}

export type Props = {
  preset?: TextPreset;
  children?: React.ReactNode;
  color?: TextColor;
  style?: React.CSSProperties;
  title?: string;
  size?: TextSize;
  transformation?: TextTransformation;
  align?: TextAlign;
  weight?: TextWeight;
  overflowEllipsis?: boolean;
  overrideLinkColor?: boolean;
  preserveLinebreaks?: boolean;
  className?: string;
  testClassName?: string;
  isEditable?: boolean;
  editableOptions?: EditableOptions;
  /** @deprecated Use <Link> component instead */
  linkTo?: string | null | undefined;
  /** @deprecated Use <Link> component instead */
  onLinkClick?: () => void;
  disabled?: boolean;
};

function Text({ preset, ...nonPresetProps }: Props) {
  let props = nonPresetProps;

  if (preset) {
    props = { ...propsForPreset(preset), ...nonPresetProps };
  }

  const {
    title,
    children,
    size,
    color,
    transformation,
    align,
    weight,
    overflowEllipsis,
    style = {},
    overrideLinkColor,
    preserveLinebreaks,
    className,
    testClassName,
    isEditable,
    editableOptions,
    linkTo,
    onLinkClick,
    disabled,
  } = props;

  const sizeClassName = classNames({
    'is-size-1': size && size === 1,
    'is-size-2': size && size === 2,
    'is-size-3': size && size === 3,
    'is-size-4': size && size === 4,
    'is-size-5': size && size === 5,
    'is-size-5_5': size && size === 5.5,
    'is-size-6': size && size === 6,
    'is-size-7': size && size === 7,
    'is-size-8': size && size === 8,
  });

  const transformationClassName = classNames({
    'is-capitalized': transformation && transformation === 'capitalized',
    'is-lowercase': transformation && transformation === 'lowercase',
    'is-uppercase': transformation && transformation === 'uppercase',
    'is-italic': transformation && transformation === 'italic',
  });

  const weightClassName = classNames({
    'has-text-weight-light': weight && weight === 'light',
    'has-text-weight-normal': weight && weight === 'normal',
    'has-text-weight-semibold': weight && weight === 'semibold',
    'has-text-weight-bold': weight && weight === 'bold',
  });

  const alignClassName = classNames({
    'has-text-left': align && align === 'left',
    'has-text-right': align && align === 'right',
    'has-text-centered': align && align === 'centered',
    'has-text-justified': align && align === 'justified',
  });

  const mergedClassName = classNames(
    sizeClassName,
    color && `has-text-${color}`,
    transformationClassName,
    weightClassName,
    alignClassName,
    className,
    testClassName,
    {
      'is-text-overflow-ellipsis': !!overflowEllipsis,
      'override-link-color': !!overrideLinkColor,
    }
  );

  if (preserveLinebreaks) style.whiteSpace = 'pre-wrap';

  if (!editableOptions) {
    if (linkTo) {
      return (
        <Link
          style={style}
          title={title}
          to={linkTo}
          className={mergedClassName}
          testClassName={testClassName}
        >
          {children}
        </Link>
      );
    }

    if (onLinkClick) {
      return (
        <Link
          style={style}
          title={title}
          onClick={() => onLinkClick()}
          className={mergedClassName}
          testClassName={testClassName}
          disabled={disabled}
        >
          {children}
        </Link>
      );
    }

    return (
      <span style={style} title={title} className={mergedClassName}>
        {children}
      </span>
    );
  }

  invariant(editableOptions, 'Text cannot be editable editableOptions');

  const content = (
    // @ts-ignore TSFIXME: fix this
    <Editable
      type={editableOptions.type}
      value={editableOptions.value}
      placeholder={editableOptions.placeholder}
      onChange={editableOptions.onChange}
      fieldProps={editableOptions.fieldProps}
      withEditIcon={editableOptions.withEditIcon}
      hasError={editableOptions.hasError}
      hasWarning={editableOptions.hasWarning}
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus={editableOptions.autoFocus}
      isDisabled={!isEditable}
      isClearable={editableOptions.isClearable}
      onBlur={editableOptions.onBlur}
      onFocus={editableOptions.onFocus}
      disabledValue={editableOptions.disabledValue}
      displayErrorMessage={editableOptions.displayErrorMessage}
    />
  );

  return (
    <span style={style} title={title} className={mergedClassName}>
      {content}
    </span>
  );
}

Text.defaultProps = {
  preserveLinebreaks: false,
  isEditable: false,
};

export default Text;
