import React from 'react';

import { open as openBeacon } from 'helpers/beacon';

import { Link } from 'components';

type Props = {
  children?: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
  prefilledSubjectToSupport?: string;
};

export default function SupportLink({
  children,
  style,
  className,
  prefilledSubjectToSupport,
}: Props) {
  if (!!window.Beacon) {
    return (
      <Link
        onClick={() => openBeacon({ subject: prefilledSubjectToSupport || '' })}
        style={style}
        overriddenClassName={className}
      >
        {children || 'support@elevo.fr'}
      </Link>
    );
  }

  return (
    <a
      href={
        'mailto:support@elevo.fr?subject=' + (prefilledSubjectToSupport || '')
      }
      target="_blank"
      rel="noopener noreferrer"
      style={style}
      className={className}
    >
      {children || 'support@elevo.fr'}
    </a>
  );
}
