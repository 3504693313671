import React, { useState } from 'react';

import type { Priority, TrainingPeriod, TrainingRequest } from 'models';

import { FormErrors } from 'helpers/api';
import { useOrganizationSettings } from 'helpers/hooks';
import { __, dateRelative } from 'helpers/i18n';
import { pathToTrainingsCatalogCourse } from 'helpers/paths';

import {
  Column,
  Columns,
  Field,
  FieldError,
  Icon,
  Input,
  Label,
  Link,
  Select,
  Text,
  Textarea,
} from 'components';

import PriorityPicker from 'scenes/components/PriorityPicker';
import TrainingCoursePicker from 'scenes/components/TrainingCoursePicker';
import TrainingPeriodPicker from 'scenes/components/TrainingPeriodPicker';
import UserPicker from 'scenes/components/UserPicker';

type Props = {
  trainingRequest: TrainingRequest;
  readOnly?: boolean;
  errors?: FormErrors;
  onChange?: (trainingRequestParams: Partial<TrainingRequest>) => void;
};

const RequestDetails = ({
  trainingRequest,
  errors,
  onChange,
  readOnly,
}: Props) => {
  const options = [
    {
      value: 'catalog',
      label: __('From the catalog'),
      icon: <Icon name="menu_book" />,
    },
    {
      value: 'offCatalog',
      label: __('Off-catalog'),
      icon: <Icon name="edit" />,
    },
  ];
  const settings = useOrganizationSettings();

  const trainingCatalogEnabled = settings.trainingCatalogEnabled;

  const [trainingOrigin, setTrainingOrigin] = useState(
    trainingRequest.trainingCourse === null || !trainingCatalogEnabled
      ? options[1]
      : options[0]
  );

  return (
    <>
      {trainingCatalogEnabled && (
        <Field>
          <Label>{__('Origin of request')}</Label>
          <Select
            value={trainingOrigin}
            options={options}
            placeholder={__('Select training origin')}
            isDisabled={!!readOnly}
            onChange={selectedOption => {
              if (!selectedOption) return;
              setTrainingOrigin(selectedOption);
              if (selectedOption.value === 'offCatalog' && onChange) {
                onChange({ trainingCourse: null, title: '' });
              }
            }}
          />
        </Field>
      )}

      {trainingOrigin.value === 'catalog' && trainingCatalogEnabled ? (
        <>
          <Field>
            {trainingRequest.trainingCourse != null ? (
              <div className="flex gap-1">
                <Label>{__('Training')}</Label>

                <Link
                  to={pathToTrainingsCatalogCourse(
                    trainingRequest.trainingCourse?.id || ''
                  )}
                  key={trainingRequest.trainingCourse?.id}
                  openInNewTab
                  // eslint-disable-next-line react/no-children-prop
                  children={undefined}
                />
              </div>
            ) : (
              <Label>{__('Training')}</Label>
            )}
            <TrainingCoursePicker
              trainingCourse={trainingRequest.trainingCourse}
              onChange={
                !!onChange
                  ? trainingCourse =>
                      onChange({ trainingCourse, title: trainingCourse?.name })
                  : () => undefined
              }
            />
          </Field>

          <Field>
            <Label>{__('Training organization')}</Label>
            <Input
              value={trainingRequest?.trainingCourse?.organism?.name || ''}
              disabled={true}
              onChange={() => {}}
            />
          </Field>
        </>
      ) : (
        <Field>
          <Label>{__('Request title')}</Label>
          <Input
            value={trainingRequest.title}
            disabled={!!readOnly}
            placeholder={__('ex: Learn the basics of Microsoft Excel')}
            onChange={!!onChange ? title => onChange({ title }) : undefined}
          />
          {!readOnly && <FieldError>{errors?.title}</FieldError>}
        </Field>
      )}

      <Field style={{ marginBottom: 0 }}>
        <Label>{__('Additional information about the request')}</Label>
        <Textarea
          value={trainingRequest.description}
          disabled={!!readOnly}
          placeholder={__(
            '- What is the purpose of this request?\n' +
              '- Why is it important for your employee’s development?\n' +
              'You can suggest one or several training organizations'
          )}
          rows={5}
          maxRows={10}
          onChange={description => !!onChange && onChange({ description })}
        />
        {!readOnly && <FieldError>{errors?.description}</FieldError>}
      </Field>
      <div style={{ marginBottom: 8, textAlign: 'right' }}>
        <Text color="light" size={7}>
          {__(
            'Created by %1 %2',
            trainingRequest.creator.fullName,
            dateRelative(trainingRequest.requestedAt)
          )}
        </Text>
      </div>
      <Columns>
        <Column>
          <Field>
            <Label>{__('Employee')}</Label>
            <UserPicker
              value={trainingRequest.trainee}
              fetchParams={{ permission: 'create_training_request?' }}
              onChange={() => {}}
              disabled
            />
            {!readOnly && <FieldError>{errors?.trainee}</FieldError>}
          </Field>
        </Column>
        <Column>
          <Field>
            <Label>{__('Request priority (details modal)')}</Label>
            <PriorityPicker
              disabled={!!readOnly}
              value={trainingRequest.priority}
              onChange={
                !!onChange
                  ? (priority: Priority) => onChange({ priority })
                  : undefined
              }
            />
            {!readOnly && <FieldError>{errors?.priority}</FieldError>}
          </Field>
        </Column>
      </Columns>
      <Columns>
        <Column size={6}>
          <Field>
            <Label>{__('Period')}</Label>
            <TrainingPeriodPicker
              disabled={!!readOnly}
              renderingStyle="select"
              currentPeriodSlug={trainingRequest.trainingPeriod.slug}
              onChange={
                onChange
                  ? (trainingPeriod: TrainingPeriod) =>
                      onChange({ trainingPeriod })
                  : undefined
              }
            />
          </Field>
        </Column>
      </Columns>
    </>
  );
};

export default RequestDetails;
