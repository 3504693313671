import React, { ReactNode } from 'react';

import useFeatureFlags from 'helpers/hooks/useFeatureFlags';
import { __ } from 'helpers/i18n';
import {
  pathToForecastTrainingPlan,
  pathToTrainingBudget,
  pathToTrainingPeriodRequests,
  pathToValidatedTrainingPlan,
} from 'helpers/paths';

import { PageHeader as DefaultPageHeader, PageTitle } from 'components';

type Props = {
  periodSlug: string;
  actions?: ReactNode;
};

const PageHeader = ({ periodSlug, actions }: Props) => {
  const featureFlags = useFeatureFlags();

  const tabItems = [
    {
      label: __('Training requests'),
      to: pathToTrainingPeriodRequests(periodSlug),
    },
    {
      label: __('Forecast plan'),
      to: pathToForecastTrainingPlan(periodSlug),
    },
    {
      label: __('Validated plan'),
      to: pathToValidatedTrainingPlan(periodSlug),
    },
  ];

  if (featureFlags.budgetEnvelopes) {
    tabItems.push({
      label: __('Budget'),
      to: pathToTrainingBudget(periodSlug),
    });
  }

  return (
    <>
      <PageTitle title={[__('Training plan')]} />
      <DefaultPageHeader
        title={__('Trainings')}
        actions={actions}
        tabItems={tabItems}
      />
    </>
  );
};

export default PageHeader;
