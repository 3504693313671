import React, { ComponentType, useState } from 'react';

import type { TrainingRequest, TrainingRequestSnapshot, User } from 'models';

import compositeKey from 'helpers/compositeKey';
import { __ } from 'helpers/i18n';
import { approvalStatusLabelWithIcon } from 'helpers/models/trainingRequest';
import { pathToUserTrainingRequests } from 'helpers/paths';

import { DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import {
  Box,
  Button,
  Can,
  FetchContainer,
  Icon,
  Link,
  SimpleTable,
  Text,
} from 'components';

import RequestManagementModal from 'scenes/components/TrainingRequestManagementModal';
import TrainingRequestModal from 'scenes/components/TrainingRequestModal';
import TrainingSessionNameTableCell from 'scenes/components/TrainingSessionNameTableCell';

import EmptyState from './EmptyState';

type Props = {
  userReviewId: string;
  reviewee: User;
  shouldFetchSnapshots: boolean;
};

type AfterDataLoaderProps = Props &
  DataLoaderProvidedProps & {
    requests: Array<TrainingRequest | TrainingRequestSnapshot>;
  };

const TrainingRequestHistoryBox = ({
  reviewee,
  isFetching,
  hasError,
  refetchData,
  requests,
}: AfterDataLoaderProps) => {
  const [isCreateRequestModalOpen, setIsCreateRequestModalOpen] =
    useState(false);
  const [foregroundRequest, setForegroundRequest] =
    useState<TrainingRequest | null>(null);

  return (
    <>
      <div className="mb-2 flex flex-col md:flex-row md:justify-between md:items-baseline">
        <Text preset="18bs5">{__('History of training requests')}</Text>
        <Can perform={'create_training_request'} on={reviewee}>
          <Button
            color="primary"
            className="w-fit mt-2 md:mt-0"
            onClick={() => setIsCreateRequestModalOpen(true)}
          >
            <Icon name="add" className="mr-1" />
            {__('Make a request')}
          </Button>
        </Can>
      </div>
      {!!requests && requests.length === 0 ? (
        <EmptyState revieweeFullName={reviewee.fullName} />
      ) : (
        <Box className="block-content-wrapper py-2 px-0">
          <FetchContainer
            isFetching={isFetching}
            hasError={hasError}
            render={() => (
              <>
                <SimpleTable
                  useSmallerYPadding
                  rows={requests}
                  keyFn={request => request.id}
                  rowClassName="test-training-request-row"
                  columns={[
                    {
                      header: __('Request title'),
                      cell: request => (
                        <div className="test-title">
                          {request.type === 'trainingRequestSnapshot' ? (
                            request.title
                          ) : (
                            <Can
                              perform={
                                'show_admin_and_superior_only_attributes'
                              }
                              on={request}
                              alternativeChildren={<Text>{request.title}</Text>}
                            >
                              <Link
                                onClick={() => setForegroundRequest(request)}
                              >
                                {request.title}
                              </Link>
                            </Can>
                          )}
                        </div>
                      ),
                    },
                    {
                      header: __('Status'),
                      cell: request =>
                        approvalStatusLabelWithIcon(request.approvalStatus),
                    },
                    {
                      header: __('Related training'),
                      cell: request => (
                        <TrainingSessionNameTableCell
                          session={request.session}
                        />
                      ),
                    },
                  ]}
                />
              </>
            )}
          />
          <Can perform="show_training_requests_and_sessions" on={reviewee}>
            <Link
              overriddenClassName="ml-4 mt-2"
              to={pathToUserTrainingRequests(reviewee.id)}
              openInNewTab
              withOpenInNewTabIcon
            >
              {__("View %1's training requests", reviewee.firstName)}
            </Link>
          </Can>
        </Box>
      )}
      {isCreateRequestModalOpen && (
        <TrainingRequestModal
          concernedUser={reviewee}
          fetchCurrentPeriodOnOpening
          createdFromReview
          onClose={() => setIsCreateRequestModalOpen(false)}
          onAfterSubmit={refetchData}
        />
      )}
      {!!foregroundRequest && (
        <RequestManagementModal
          trainingRequest={foregroundRequest}
          onClose={params => {
            setForegroundRequest(null);

            if (params?.shouldRefetchData) {
              refetchData();
            }
          }}
          linkedSessionName={foregroundRequest.session?.name || null}
          currentPeriodSlug={foregroundRequest.trainingPeriod.slug}
        />
      )}
    </>
  );
};

export default newDataLoader({
  fetch: ({ userReviewId, shouldFetchSnapshots }: Props) => {
    const endpoint = `user_reviews/${userReviewId}/${
      shouldFetchSnapshots ? 'training_request_snapshots' : 'training_requests'
    }`;

    return get(endpoint);
  },
  hydrate: {
    requests: {
      trainee: {},
      creator: {},
      session: {},
      trainingPeriod: {},
      trainingCourse: {},
    },
  },
  cacheKey: ({ userReviewId }: Props) =>
    compositeKey({
      type: 'trainingRequestsForUserReview',
      userReviewId,
    }),
})(TrainingRequestHistoryBox) as ComponentType<Props>;
