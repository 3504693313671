import classNames from 'classnames';
import React, { ReactNode } from 'react';

import { Link } from 'components';

type Props = {
  children: ReactNode;
  url?: string;
  className?: string;
};

const Card = ({ children, url, className }: Props) => {
  if (url)
    return (
      <Link to={url} overriddenClassName={classNames('elevo-card', className)}>
        {children}
      </Link>
    );
  else {
    return (
      <div className={classNames('elevo-card', className)}>{children}</div>
    );
  }
};

export default Card;
