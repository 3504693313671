import React from 'react';

import { SurveyCampaign } from 'models';

import useFeatureFlags from 'helpers/hooks/useFeatureFlags';
import { __ } from 'helpers/i18n';

import { EmptyStateWithIcon } from 'components';
import { ActiveFilters } from 'components/Filters/types';

import QuestionPicker from './QuestionPicker';

type Props = {
  campaign: SurveyCampaign;
  selectedPeriodId: string;
  userFilters: ActiveFilters | '';
  onUserFiltersChange: (newQueryParams: '' | ActiveFilters) => void | undefined;
  questionId?: string | null;
  correlationSlug?: string | null;
};

const DetailedResults = ({
  campaign,
  selectedPeriodId,
  userFilters,
  onUserFiltersChange,
  questionId,
  correlationSlug,
}: Props) => {
  const featureFlags = useFeatureFlags();
  const filters = featureFlags.surveyFilteringDisabled ? '' : userFilters;

  if (!questionId && !selectedPeriodId && !correlationSlug) {
    const title =
      campaign.status === 'launching'
        ? __('This survey is being launched')
        : __('This survey has not been launched yet');
    const description =
      campaign.status === 'launching'
        ? __('This may take a couple of minutes. Come back soon 🙂.')
        : __(
            "Once launched, you'll find the detailed results of your survey here."
          );

    return (
      <EmptyStateWithIcon
        iconName="equalizer"
        title={title}
        description={description}
        testClassName={`test-survey-overview-${
          campaign.status === 'launching' ? 'launching' : 'empty-state'
        }`}
      />
    );
  } else {
    return (
      <QuestionPicker
        campaign={campaign}
        selectedPeriodId={selectedPeriodId}
        userFilters={filters}
        onUserFiltersChange={onUserFiltersChange}
        questionId={questionId}
        correlationSlug={correlationSlug}
      />
    );
  }
};

export default DetailedResults;
