// from https://raw.githubusercontent.com/callemall/material-ui/v1-beta/src/internal/ClickAwayListener.js
import React from 'react';
import { findDOMNode } from 'react-dom';
import EventListener from 'react-event-listener';

import type { ReactNode } from 'react';

const isDescendant = (el: Element | null, target: Element | null) => {
  if (target !== null && target.parentNode) {
    return el === target || isDescendant(el, target.parentElement);
  }
  return false;
};

type Props = {
  children: ReactNode;
  enabled?: boolean;
  onClickAway: (event: Event) => void;
};

export default class ClickAwayListener extends React.Component<Props> {
  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  mounted = false;
  mouseDownRegistered = false;

  registerMouseDown = (_event: Event) => {
    this.mouseDownRegistered = true;
  };

  onKeyPress = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      this.props.onClickAway(event);
    }
  };

  handleClickAway = (event: Event) => {
    if (event.defaultPrevented) return;
    if (!this.mouseDownRegistered) return;

    // IE11 support, which trigger the handleClickAway even after the unbind
    if (this.mounted) {
      // eslint-disable-next-line react/no-find-dom-node
      const el = findDOMNode(this);

      if (
        event.target instanceof Element &&
        el instanceof Element &&
        document.documentElement &&
        document.documentElement.contains(event.target) &&
        !isDescendant(el, event.target) &&
        !isDescendant(el.closest('.inline-editable-wrapper'), event.target)
      ) {
        this.props.onClickAway(event);
      }
    }
  };

  render() {
    let { enabled } = this.props;

    if (typeof enabled === 'undefined') {
      enabled = true;
    }

    return (
      <EventListener
        target="document"
        onMouseup={enabled && this.handleClickAway}
        onTouchend={enabled && this.handleClickAway}
        onMousedown={enabled && this.registerMouseDown}
        onTouchstart={enabled && this.registerMouseDown}
        onKeyDown={enabled && this.onKeyPress}
      >
        {this.props.children}
      </EventListener>
    );
  }
}
