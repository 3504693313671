/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { MouseEvent } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';

import classNames from 'helpers/classNames';
import invariant from 'helpers/invariant';

import { Icon } from 'components';

import LinkWithPromise from './LinkWithPromise';

type Props = {
  to?: string;
  children: React.ReactNode;
  isPrimary?: boolean;
  openInNewTab?: boolean;
  withOpenInNewTabIcon?: boolean;
  disabled?: boolean;
  style?: React.CSSProperties;
  title?: string;
  onClick?: (e: MouseEvent) => void;
  onMouseDown?: () => void;
  onKeyDown?: React.KeyboardEventHandler<HTMLAnchorElement>;
  overriddenClassName?: string | null;
  className?: string;
  testClassName?: string;
  tabIndex?: number;
  /**
   * @deprecated Has no effect.
   * Type declaration to be removed after a few days
   **/
  hasInheritedColor?: boolean;
};

export default function Link({
  to,
  children,
  isPrimary,
  openInNewTab,
  withOpenInNewTabIcon = true,
  disabled,
  style,
  title,
  onClick,
  onMouseDown,
  onKeyDown,
  overriddenClassName,
  className,
  testClassName,
  tabIndex,
}: Props) {
  const actuallyDisabled = disabled || (!to && !onClick);
  const finalClassName =
    overriddenClassName === null
      ? ''
      : overriddenClassName ||
        classNames('link', className, testClassName, {
          'is-primary': isPrimary,
          'is-disabled': actuallyDisabled,
        });

  if (actuallyDisabled) {
    return (
      <a className={finalClassName} style={style} tabIndex={tabIndex}>
        {children}
      </a>
    );
  }

  if (openInNewTab) {
    return (
      <a
        title={title}
        href={to}
        className={finalClassName}
        target="_blank"
        style={style}
        tabIndex={tabIndex}
        rel="noreferrer"
      >
        {children}
        {withOpenInNewTabIcon && ' ' && (
          <Icon
            size="tiny"
            style={{ verticalAlign: 'text-bottom' }}
            name="open_in_new"
          />
        )}
      </a>
    );
  }

  if (onClick || onMouseDown) {
    return (
      <LinkWithPromise
        onClick={onClick}
        onMouseDown={onMouseDown}
        className={finalClassName}
        style={style}
        onKeyDown={onKeyDown}
        tabIndex={tabIndex}
      >
        {children}
      </LinkWithPromise>
    );
  }

  invariant(to, 'to must be defined at this point');

  return (
    <ReactRouterLink
      style={style}
      className={finalClassName}
      to={to}
      tabIndex={tabIndex}
    >
      {children}
    </ReactRouterLink>
  );
}
